.mobile-nav {
  padding: 0 48px 56px 48px;
  @include bp($lg) {
    padding: initial;
  }
}

#main-menu {
  transition: height 0.5s $transition-main;
  will-change: height;
}

.navbar-collapse {
  background-color: $primary-4-5;
  position: initial;
  width: 100%;
  flex-basis: initial !important;
  @include bp($lg) {
    background-color: initial;
    margin-right: 16px;
    background-color: initial;
  }
  &::before {
    content: " ";
    display: block;
    position: absolute;
    height: 1px;
    background-color: $td-border-color;
    width: calc(100% + 52px);
    left: -26px;
    z-index: 2;
  }
  @include bp($lg) {
    &::before {
      display: none;
    }
  }
}

.nav-primary {
  @include bp($lg) {
    padding: initial;
    max-width: 52rem;
    &::before {
      display: none;
    }
  }
  @include bp($xl) {
    max-width: 78rem;
  }
}

#masthead {
  .navbar-collapse {
    > .navbar {
      --bs-nav-link-padding-y: 0.5rem;
      --bs-nav-link-padding-x: 0.5rem;
      list-style: none;
      padding: 0;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      @include bp($lg) {
        flex-flow: row;
        align-items: center;
      }
      > .menu-item {
        @include font-size(18);
        font-weight: 400;
        font-family: $font-family-oswald;
        text-transform: uppercase;
        line-height: 1;
        letter-spacing: 0.0125em;
        > .nav-link {
          padding-left: var(--bs-navbar-nav-link-padding-x);
          padding-right: var(--bs-navbar-nav-link-padding-x);
          text-decoration-color: transparent;
          &::before {
            content: " ";
            display: block;
            position: absolute;
            left: var(--bs-navbar-nav-link-padding-x);
            width: 0;
            height: 2px;
            background: $primary;
            bottom: 6px;
            transition: width 0.15s $transition-main;
          }
        }
        &:hover,
        &:active,
        &:focus,
        &.active,
        &.current-menu-item {
          > .nav-link::before {
            width: calc(100% - (var(--bs-navbar-nav-link-padding-x) * 2));
          }
        }
      }
      &.nav-primary {
        padding: 42px 40px 21px 40px;
        @include bp($lg) {
          padding: 0;
        }
        > .menu-item {
          > .nav-link {
            color: $primary;
          }
        }
      }
      &.mobile-nav__top-links {
        padding: 0 40px 42px 40px;
        @include bp($lg) {
          padding: 0;
        }
        > .menu-item {
          > .nav-link {
            position: relative;
            color: $green;
            &::before {
              background-color: $green;
            }
          }
        }
        .menu-item.menu-linkedin {
          margin-top: 21px;
          > .nav-link {
            position: relative;
            color: $primary;
            &::before {
              background-color: $primary;
            }
          }
        }
      }
    }
  }
}

.navbar-toggler {
  &.collapsed {
    .bar1,
    .bar3 {
      transform: none;
    }
    .bar2 {
      opacity: 1;
    }
  }
  .bar1,
  .bar2,
  .bar3 {
    width: 24px;
    height: 3px;
    background-color: $primary-light;
    margin: 4px 0;
    transition: 0.4s;
    border-radius: 1.5px;
  }
  .bar1 {
    transform: translate(0, 7px) rotate(-45deg);
  }
  .bar2 {
    opacity: 0;
  }
  .bar3 {
    transform: translate(0, -7px) rotate(45deg);
  }
}
