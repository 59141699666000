.flexi-two-col-image-heading-text-block {
  .flexi-two-col__img {
    aspect-ratio: 4.125 / 3;
    width: 100%;
    display: block;
    margin-inline: auto;
    max-height: 42vh;

    @include bp($md) {
      max-height: initial;
    }

    object-fit: cover;
  }
}

.flexi-two-thirds-one-third-block__col--is-fp-svg {
  position: absolute;
  top: -3rem;
  margin-left: -15px;
  @include bp($lg) {
    position: initial;
    top: initial;
    margin-left: initial;
  }
}

.flexi-two-col-image-heading-text-block {
  &:has(~.flexi-colored-cta-block) {
    padding-bottom: 7.5rem !important;
  }
  +.flexi-colored-cta-block {
    margin-top: -5rem !important;
    margin-bottom: 0 !important;
  }
}
