.list-bullet-line {
  display: flex;
  flex-flow: column;
  padding-left: 24px;
  >li {
    position: relative;
    display: inline-flex;
    width: 100%;
    padding-bottom: 1rem;
    &:last-of-type {
      padding-bottom: 0;
    }
    >p {
      display: block;
      color: #fff;
    }
    &::before {
      content: " ";
      position: absolute;
      width: 1px;
      height: 100%;
      background: currentColor;
      left: -20px;
    }
    &::after {
      content: "•";
      position: absolute;
      left: -26px;
      top: 50%;
      width: 0px;
      line-height: 0px;
      color: currentColor;
      font-size: 27px;
    }
  }
  @include bp($md) {
    display: flex;
    flex-flow: row;
    >li {
      position: relative;
      display: inline-flex;
      width: 100%;
      text-align: center;
      >p {
        display: block;
        margin-inline: auto;
        max-width: 282px;
        padding: 1rem;
        color: #fff;
        margin-block: 0;
      }
      &::before {
        content: " ";
        position: absolute;
        width: 100%;
        height: 1px;
        background: currentColor;
        top: -6px;
      }
      &::after {
        content: "•";
        height: 1px;
        position: absolute;
        top: -7px;
        width: 100%;
        line-height: 0px;
        color: currentColor;
        font-size: 26px;
        left: 0%;
      }
    }
  }
}

.list-bullet-line--vertical {
  display: flex;
  flex-flow: column;
  padding-left: 24px;
  @include bp($lg) {
    padding-left: 48px;
  }
  >li {
    position: relative;
    display: inline-flex;
    width: 100%;
    padding-bottom: 1rem;
    &:last-of-type {
      padding-bottom: 0;
    }
    >p {
      display: block;
      color: currentColor;
    }
    &::before {
      content: " ";
      position: absolute;
      width: 1px;
      height: 100%;
      background: currentColor;
      left: -20px;
      @include bp($lg) {
        left: -44px;
      }
    }
    &::after {
      content: "•";
      position: absolute;
      left: -26px;
      @include bp($lg) {
        left: -50.5px;
      }
      top: 50%;
      width: 0px;
      line-height: 0px;
      color: currentColor;
      font-size: 27px;
    }
  }
}

.flexi-award-block.flexi-award-block--lower .f-display-2 {
  @include bp(0 $md) {
    font-size: 20px;
  }
}
