/*------------------------------------*\
	# FORMS MASTER
\*------------------------------------*/

@import "../../variables-settings/form-settings";

button,
input,
select,
textarea {
	font-size: 100%; /* Corrects font size not being inherited in all browsers */
	margin: 0; /* Addresses margins set differently in IE6/7, F3/4, S5, Chrome */
	vertical-align: baseline; /* Improves appearance and consistency in all browsers */
	font-family: $font__sans-serif;
}

form ul,
form ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

form li {
	display: block;
	margin: 0;
	padding: 0;
}

form {
	@include font-size(15);
}

#input_3_25 {
	height: 120px;
}

.gfield--type-honeypot {
	display: none !important;
}

body img.gform_ajax_spinner {
	width: 40px;
	margin-left: 20px;
}

.gform_required_legend {
	display: none;
}

.site-content .gform_wrapper.gravity-theme {
	.gfield_label {
		font-family: $font-family-inter;
		line-height: 1.847;
		letter-spacing: 0.0125em;
		font-weight: 400;
		@include font-size(13);
		color: $primary;
		margin-bottom: 7px;
	}
	.gfield {
		margin-bottom: 12px;
		@include bp($lg) {
			margin-bottom: 20px;
		}
		font-family: $font-family-inter;
		line-height: 1.847;
		letter-spacing: 0.0125em;
		font-weight: 400;
		@include font-size(13);
		color: $primary;
	}
	.gfield::placeholder  {
		color: #121212;
	}
	.gchoice.form-check {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		label {
			margin-left: 14px;
		}
	}
	.gfield.gfield--type-submit>button {
		border: 1px solid $primary;
		display: flex;
		align-items: center;
		color: $primary;
		font-family: $font-family-inter;
		line-height: (29/14);
		letter-spacing: 0.1em;
		font-weight: 500;
		@include font-size(14);
		padding: 8px 16px;
	}
}
