.flexi-video-block__heading {
  text-align: center;
  font-family: $font-family-roboto-slab;
  @include font-size(24);
  line-height: (32/24);
  max-width: 100%;
  @include bp($md) {
    @include font-size(32);
    line-height: (42/32);
    max-width: 628px;
  }
  @include bp($lg) {
    @include font-size(35);
    line-height: (45/35);
    max-width: 694px;
  }
}

.flexi-video-block__col {
  iframe {
    aspect-ratio: 16 / 9;
    width: 100%;
    height: auto;
    margin-bottom: 1rem;
    display: block;
  }
}
