/*------------------------------------*\
	# HEADINGS
\*------------------------------------*/

[class*="f-display-"],
h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
	color: color__(text-headings);
	margin: 0;
	line-height: 1.3;

	// Headings should inherit colour from wrappers or utility classes
	color: currentColor;
	font-family: $font__main;
}

[class*="f-display-"],
h1,
h2,
h3,
h4 {
	text-transform: none; // If this is defaulted to uppercase it could be confusing on builds.
	font-weight: normal;
}

.f-display-1,
.f-display-1 * {
	text-transform: uppercase;
	line-height: 1.2;
	letter-spacing: 0.08em;
	@include font-size(30);

	@include bp($md) {
		@include font-size(40);
	}

	@include bp($lg) {
		@include font-size(50);
	}
}

.f-display-2,
.f-display-2 * {
	font-family: $font-family-roboto-slab;

	@include font-size(30);
	line-height: 1.36;
	@include bp($md) {
		@include font-size(32);
		line-height: 1.42;
	}
	@include bp($lg) {
		@include font-size(35);
		line-height: 1.45;
	}
}

.f-display-3,
.f-display-3 * {
	font-family: $font-family-roboto-slab;
	@include font-size(24);
	line-height: (32/24);

	@include bp($lg) {
		@include font-size(28);
		line-height: (37/28);
	}
}

.f-display-4,
.f-display-4 * {
	letter-spacing: 0.05em;
	@include font-size(22);

	@include bp($md) {
		@include font-size(25);
	}

	@include bp($lg) {
		@include font-size(28);
	}
}

.f-display-5,
.f-display-5 * {
	letter-spacing: 0.05em;
	@include font-size(18);

	@include bp($tablet-p) {
		@include font-size(20);
	}

	@include bp($lg) {
		@include font-size(22);
	}
}

.f-display-6,
.f-display-6 * {
	letter-spacing: 0.05em;
	@include font-size(16);

	@include bp($md) {
		@include font-size(18);
	}

	@include bp($lg) {
		@include font-size(20);
	}
}

.f-display-8,
.f-display-8 * {
	line-height: 1.32;
	letter-spacing: 0;
	font-weight: 400;
	font-family: $font-family-roboto-slab;
	@include font-size(24);

	@include bp($md) {
		@include font-size(22);
	}

	@include bp($xl) {
		@include font-size(35);
	}
}

.f-display-9,
.f-display-9 * {
	line-height: 1.35;
	letter-spacing: 0;
	font-weight: 400;
	font-family: $font-family-roboto-slab;
	@include font-size(24);

	@include bp($md) {
		@include font-size(32);
	}

	@include bp($xl) {
		@include font-size(35);
	}
}

.f-display-10,
.f-display-10 * {
	line-height: 1.4;
	letter-spacing: 0.01em;
	font-weight: 400;
	font-family: $font-family-oswald;
	@include font-size(35);
}

.f-display-11,
.f-display-11 * {
	line-height: 1;
	letter-spacing: 0em;
	font-weight: 400;
	font-family: $font-family-roboto-slab;
	@include font-size(35);

	@include bp($md) {
		@include font-size(22);
	}

	@include bp($xl) {
		@include font-size(35);
	}
}

.f-display-12,
.f-display-12 * {
	@include font-size(20);
	letter-spacing: 0em;
	line-height: 1.4;
	font-weight: 400;
	font-family: $font-family-roboto-slab;

	@include bp($md) {
		@include font-size(22);
	}

	@include bp($xl) {
		@include font-size(28);
	}
}

.f-display-13t {
	line-height: (38/32);
	letter-spacing: 0.01em;
	font-weight: 400;
	font-family: $font-family-oswald;
	@include font-size(32);
}

.f-display-14t {
	line-height: 1;
	letter-spacing: 0.01em;
	font-weight: 400;
	font-family: $font-family-oswald;
	@include font-size(70);
}

.f-display-15t {
	line-height: (22/14);
	letter-spacing: 0.1em;
	font-weight: 700;
	font-family: $font-family-inter;
	@include font-size(14);
	text-transform: uppercase;
}

.f-display-16t {
	line-height: (16/12);
	letter-spacing: 0.1em;
	font-weight: 700;
	font-family: $font-family-inter;
	@include font-size(12);
	text-transform: uppercase;
}

.f-display-14,
.f-display-14 * {
	line-height: 1.3;
	letter-spacing: 0.01em;
	font-weight: 400;
	font-family: $font-family-oswald;
	@include font-size(32);
}


.f-display-16,
.f-display-16 * {
	line-height: 1.32;
	letter-spacing: 0;
	font-weight: 400;
	font-family: $font-family-roboto-slab;
	@include font-size(24);
}

.f-display-17,
.f-display-17 * {
	line-height: 1.22;
	letter-spacing: 0.01em;
	font-weight: 400;
	font-family: $font-family-roboto-slab;
	@include font-size(18);
}

.f-display-18,
.f-display-18 * {
	line-height: 1.32;
	letter-spacing: 0.01em;
	font-weight: 600;
	font-family: $font-family-roboto-slab;
	@include font-size(26);
}

.f-display-19,
.f-display-19 * {
	line-height: 1.33;
	letter-spacing: 0.01em;
	font-weight: 400;
	font-family: $font-family-oswald;
	@include font-size(22);
}

.f-display-21,
.f-display-21 * {
	@include font-size(28);
	letter-spacing: 0em;
	line-height: 1.37;
	font-weight: 400;
	font-family: $font-family-roboto-slab;
	word-wrap: break-word;

	@include bp($md) {
		@include font-size(22);
	}

	@include bp($xl) {
		@include font-size(28);
	}
}

.f-display-23,
.f-display-23 * {
	line-height: 1;
	letter-spacing: 0.01em;
	font-weight: 400;
	font-family: $font-family-oswald;
	@include font-size(33);
}

.f-display-24,
.f-display-24 * {
	line-height: 1.17;
	letter-spacing: 0.07em;
	font-weight: 400;
	font-family: $font-family-oswald;
	@include font-size(12);
	@include bp($md) {
		@include font-size(14);
		line-height: 1.20;
	}
}


.f-display-feature {
  text-transform: initial !important;
  font-weight: 300 !important;
  b, strong, .text-bold, .fw-700 {
    font-weight: 400 !important;
  }
}
