/*------------------------------------*\
	# COPY
\*------------------------------------*/

p,
ul,
ol,
pre,
table,
blockquote {
	margin: 0;
	line-height: 1.5;
}

hr {
	background: $background-hr;
	border: none;
	margin: -1px 0;
	height: 1px;
}

ul ul,
ol ol,
ul ol,
ol ul {
	margin-top: 0em;
	margin-bottom: 0em;
}

a,
b,
i,
strong,
em,
small,
code {
	line-height: 1.5;
}

b,
strong {
	font-weight: bold;
}

b,
strong,
em,
small,
code {
	line-height: 1;
}

dfn,
cite,
em,
i {
	font-style: italic;
}

pre {
	background: color__(background-pre);
	font-family: $font__pre;
	line-height: 1.6;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code,
kbd,
tt,
var {
	font-family: $font__code;
}

abbr,
acronym {
	border-bottom: 1px dotted color__(border-abbr);
	cursor: help;
}

mark,
ins {
	background: color__(background-ins);
	text-decoration: none;
}

sup,
sub {
	font-size: 75%;
	height: 0;
	line-height: 0;
	position: relative;
	top: -0.4em;
	vertical-align: baseline;
}

sub {
	top: 0.4em;
}

small {
	font-size: 75%;
}

big {
	font-size: 125%;
}

blockquote {
	font-family: $font-family-inter;
}

cite {
	font-style: normal;
}

/* = BODY
----------------------------------------------- */

[class*="f-body-"] p + p {
	margin-top: 16px;
}

[class*="f-body-"] {
	font-family: $font-family-inter;
	letter-spacing: 0.0125em;
	font-weight: 400;

	@include font-size(14);

	@include bp($md) {
		@include font-size(15);
	}

	@include bp($lg) {
		@include font-size(16);
	}
}

.f-body-2 {
	@include font-size(12);
	letter-spacing: 0.0125em;

	@include bp($lg) {
		@include font-size(14);
	}
}

.f-body-3 {
	@include font-size(16);
	letter-spacing: 0.0125em;
	line-height: 1.25;
	font-weight: 400;
	font-family: $font-family-inter;
	@include bp($md) {
		@include font-size(18);
		line-height: 1.26;
	}

	@include bp($xl) {
		@include font-size(20);
		line-height: 1.3;
	}
}

.f-body-4 {
	@include font-size(16);
	letter-spacing: 0.0125em;
	line-height: 1.25;
	font-weight: 400;
	font-family: $font-family-inter;
}

.f-body-5 {
	@include font-size(14);
	letter-spacing: 0.0125em;
	line-height: 1.22;
	font-weight: 400;
	font-family: $font-family-inter;
}

.f-body-6 {
	@include font-size(20);
	letter-spacing: 0.0125em;
	line-height: 1.26;
	font-weight: 400;
	font-family: $font-family-inter;
}

.f-body-7 {
	letter-spacing: 0.0125em;
  @include font-size(16);
  line-height: 1.25;
  font-weight: 400;
	font-family: $font-family-inter;
}

.f-body-8 {
	@include font-size(16);
	letter-spacing: 0.0125em;
	line-height: 1.5;
	font-weight: 400;
	font-family: $font-family-inter;

	@include bp($md) {
		@include font-size(18);
	}

	@include bp($xl) {
		@include font-size(20);
	}
}

.f-body-9 {
	@include font-size(11);
	letter-spacing: 0.0125em;
	line-height: 1.18;
	font-weight: 400;
	font-family: $font-family-inter;

	@include bp($md) {
		@include font-size(14);
		line-height: 1.22;
	}

	@include bp($xl) {
		@include font-size(16);
		line-height: 1.25;
	}
}

.f-body-10 {
	@include font-size(14);
	letter-spacing: 0.0125em;
	line-height: 1.23;
	font-weight: 400;
	font-family: $font-family-inter;

	@include bp($md) {
		line-height: 1.22;
	}

	@include bp($xl) {
		@include font-size(16);
		line-height: 1.25;
	}
}

.f-body-11 {
	@include font-size(14);
	letter-spacing: 0.0125em;
	line-height: 1.22;
	font-weight: 400;
	font-family: $font__sans-serif;

	@include bp($md) {
		@include font-size(18);
		line-height: 1.26;
	}

	@include bp($xl) {
		@include font-size(20);
		line-height: 1.3;
	}
}

.f-body-12 {
	@include font-size(18);
	letter-spacing: 0.0125em;
	line-height: 1.28;
	font-weight: 400;
	font-family: $font__sans-serif;

	@include bp($md) {
		@include font-size(20);
		line-height: 1.26;
	}

	@include bp($xl) {
		line-height: 1.3;
	}
}

.f-body-13 {
	@include font-size(20);
	letter-spacing: 0.0125em;
	line-height: 1.3;
	font-weight: 400;
	font-family: $font__sans-serif;
}

.f-body-14 {
	@include font-size(16);
	letter-spacing: 0.0125em;
	line-height: 1.25;
	font-weight: 400;
	font-family: $font__sans-serif;

	@include bp($xl) {
		@include font-size(20);
		line-height: 1.3;
	}
}

.f-body-15 {
	@include font-size(16);
	letter-spacing: 0.01em;
	line-height: 1.24;
	font-weight: 400;
	font-family: $font__serif;
}

.f-body-16 {
	@include font-size(16);
	letter-spacing: 0.0125em;
	line-height: 1.25;
	font-weight: 400;
	font-family: $font-family-inter;

	@include bp($md) {
		@include font-size(12);
		line-height: 1.19;
	}

	@include bp($xl) {
		@include font-size(16);
		line-height: 1.25;
	}
}

.f-body-17 {
	@include font-size(16);
	letter-spacing: 0.0125em;
	line-height: 1.25;
	font-weight: 500;
	font-family: $font-family-inter;

	@include bp($md) {
		@include font-size(18);
		line-height: 1.25;
	}

	@include bp($xl) {
		@include font-size(20);
	}
}

.f-body-18 {
	@include font-size(11);
	letter-spacing: 0.0125em;
	line-height: 1.18;
	font-weight: 400;
	font-family: $font-family-inter;

	@include bp($md) {
		@include font-size(12);
		line-height: 1.19;
	}

	@include bp($xl) {
		@include font-size(14);
		line-height: 1.22;
	}
}

.f-body-19 {
	@include font-size(16);
	letter-spacing: 0.0125em;
	line-height: 1.25;
	font-weight: 400;
	font-family: $font-family-inter;

	@include bp($md) {
		@include font-size(18);
		line-height: 1.26;
	}

	@include bp($xl) {
		@include font-size(20);
		line-height: 1.3;
	}
}

.f-body-20 {
	@include font-size(12);
	letter-spacing: 0.01em;
	line-height: (24/16);
	font-weight: 400;
	font-family: $font-family-roboto-slab;
	color: $primary;
	@include bp($md) {
		@include font-size(14);
	}

	@include bp($xl) {
		@include font-size(16);
	}
}

.f-body-21 {
	@include font-size(14);
	letter-spacing: 0.0125em;
	line-height: 1.22;
	font-weight: 400;
	font-family: $font-family-inter;
}

.f-body-22 {
	@include font-size(11);
	letter-spacing: 0.0125em;
	line-height: 1.18;
	font-weight: 400;
	font-family: $font-family-inter;

	@include bp($md) {
		@include font-size(16);
		line-height: 1.25;
	}
}

.f-body-23 {
	@include font-size(12);
	letter-spacing: 0.0125em;
	line-height: 1.18;
	font-weight: 400;
	font-family: $font-family-inter;
	@include bp($md) {
		@include font-size(14);
		line-height: 1.22;
	}
}

.f-body-24 {
	@include font-size(14);
	letter-spacing: 0.0125em;
	line-height: 1.20;
	font-weight: 400;
	font-family: $font-family-inter;
}

.f-body-25 {
	@include font-size(8);
	letter-spacing: 0.0125em;
	line-height: 1.11;
	font-weight: 400;
	font-family: $font-family-inter;
	@include bp($md) {
		@include font-size(10);
		line-height: 1.14;
	}
}

.f-body-26 {
	@include font-size(16);
	letter-spacing: 0.0125em;
	line-height: 1.25;
	font-weight: 400;
	font-family: $font-family-inter;

	@include bp($md) {
		@include font-size(14);
		line-height: 1.22;
	}

	@include bp($xl) {
		@include font-size(16);
		line-height: 1.25;
	}
}

.text-content {
	font-family: $font-family-inter;
	letter-spacing: 0.0125em;
	font-weight: 400;
	--fs-base: clamp(1rem, 0vi + 1rem, 1rem);
	--fs-fluid: clamp(1rem, 0.25vi + 0.95rem, 1.25rem);
	font-size: var(--fs-fluid);
	// @include font-size(16);
	line-height: (25/16);
	@include bp($lg) {
		// @include font-size(18);
		line-height: (26/18);
	}
	@include bp($xl) {
		// @include font-size(20);
		line-height: (30/20);
	}
	a {
		color: var(--color-green);
	}
	a {
		&:hover,
		&:active,
		&:focus {
			text-decoration: underline;
		}
	}
	a[href^="mailto"] {
		word-break: break-all;
	}
}

.text-content-sm {
	font-family: $font-family-inter;
	line-height: (25/16);
	letter-spacing: 0.0125em;
	font-weight: 400;
	// @include font-size(16);
	font-size: clamp(0.8rem, 0.25vi + 0.7rem, 1rem);
}

.text-content-xs {
	font-family: $font-family-inter;
	line-height: (20/13);
	letter-spacing: 0.0125em;
	font-weight: 400;
	@include font-size(13);
}

.text-content-xxs {
	font-family: $font-family-inter;
	line-height: (20/11);
	letter-spacing: 0.0125em;
	font-weight: 400;
	@include font-size(11);
	a[href^="mailto"] {
		word-break: break-all;
	}
}

/* = EMAIL WRAPS
----------------------------------------------- */

.text-content-sm a[href^="mailto"],
.text-content-xs a[href^="mailto"] {
	word-break: break-all;
}

.no-email-wrap a[href^="mailto"] {
	word-break: normal;
}