/*------------------------------------*\
  # COLOURS
\*------------------------------------*/


/* = TEXT
----------------------------------------------- */

@each $color_name, $color_value in $color-vars-palette {
  .color-#{""+$color_name} {
    color: $color_value;
  }
  .text-#{""+$color_name} {
    color: $color_value !important;
  }
  .text-#{""+$color_name} *:not(video):not([class*=plyr]):not([class^=icon]) {
    color: $color_value;
  }
}

@each $color_name, $color_value in $color-vars-applied {
  .color-#{""+$color_name} {
    color: $color_value;
  }
  .text-#{""+$color_name} {
    color: $color_value !important;
  }
  .text-#{""+$color_name} *:not(video):not([class*=plyr]):not([class^=icon]) {
    color: $color_value;
  }
}


/* = BACKGROUND
----------------------------------------------- */

@each $color_name, $color_value in $color-vars-palette {
  .bg-#{""+$color_name} {
    background-color: $color_value !important;
  }
}

@each $color_name, $color_value in $color-vars-applied {
  .bg-#{""+$color_name} {
    background-color: $color_value !important;
  }
}

/* = BORDER
----------------------------------------------- */
