.flexi-certifications-block {
    a {
        font-weight: 700;
        color: $neutral-1-2;
        &:hover,
        &:focus {
            color: $primary-3-1;
        }
    }
}
