.flexi-two-col-list-image-block {
    --bs-border-color: currentColor;
    &.secondary-3-1 {
        --bs-border-color: #{""+$secondary-2-1} !important;
        .f-display-14,
        .f-display-14 * {
            color: $secondary-2-1 !important;
        }
    }
    &.primary-2-6 {
        --bs-border-color: #{""+$primary-2-1} !important;
        .f-display-14,
        .f-display-14 * {
            color: $primary-2-1 !important;
        }
    }
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-height: 525px;
        @include bp($md) {
            max-height: initial;
            position: absolute;
        }
    }
    &.primary-4-7 {
        .f-display-14,
        .f-display-14 * {
            color: $primary;
            font-family: $font-family-roboto-slab;
        }
    }
}

