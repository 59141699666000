/*------------------------------------*\
	# SHAME, SHAME, SHAME!
	- Just kidding it's just a good place to dump things
	when you don't know here they go yet.
\*------------------------------------*/

body {
  overflow-x: hidden;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.mw-xl-870 {
  @include bp($lg) {
    max-width: 780px;
  }

  @include bp($xl) {
    max-width: 870px;
  }
}

.mw-xl-550 {
  @include bp($md) {
    max-width: 460px;
  }

  @include bp($xl) {
    max-width: 550px;
  }
}

.mw-sm-350-md-325 {
  max-width: 350px;

  @include bp($md) {
    max-width: 325px;
  }
}

.mw-430 {
  max-width: 430px;
}

.mw-450 {
  max-width: 450px;
}

.mw-md-430 {
  @include bp($md) {
    max-width: 430px;
  }
}

.mw-xl-430 {
  @include bp($xl) {
    max-width: 430px;
  }
}

.mw-504 {
  max-width: 504px;
}

.mw-554 {
  max-width: 554px;
}

.mw-750 {
  max-width: 750px;
}

.mw-632 {
  max-width: 632px;
}

.mw-600 {
  max-width: 600px;
}

.mw-400 {
  max-width: 400px;
}

.mw-650 {
  max-width: 650px;
}

.mw-700 {
  max-width: 700px;
}

.mw-lg-372 {
  @include bp($lg) {
    max-width: 372px;
  }
}

.mw-lg-604 {
  @include bp($lg) {
    max-width: 604px;
  }
}

.mw-xl-566 {
  @include bp($xl) {
    max-width: 566px;
  }
}

.mw-md-605 {
  @include bp($md) {
    max-width: 605px;
  }
}

.mw-210-md-575 {
  max-width: 210px;

  @include bp($md) {
    max-width: 575px;
  }
}

.mw-xl-600 {
  @include bp($xl) {
    max-width: 600px;
  }
}

.mw-240 {
  max-width: 240px;
}

.mw-486 {
  max-width: 486px;
}

.mw-715 {
  max-width: 715px;
}

.mw-725 {
  max-width: 725px;
}

.mw-745 {
  max-width: 745px;
}

.mw-820 {
  max-width: 820px;
}

.mw-xl-960 {
  @include bp($xl) {
    max-width: 960px;
  }
}

.mw-465 {
  max-width: 465px;
}

.mw-lg-975 {
  @include bp($lg) {
    max-width: 975px;
  }
}

.mw-xl-535 {
  max-width: 465px;

  @include bp($xl) {
    max-width: 535px;
  }
}

.mw-min-729 {
  @include bp($xl) {
    min-width: 729px;
  }
}

.mw-175 {
  max-width: 175px;
}

.mw-985 {
  max-width: 985px;
}

.mw-xs-210-sm-0 {
  max-width: 210px;
  @include bp($sm) {
    max-width: initial;
  }
}

.mw-xs-135-md-0 {
  max-width: 135px;
  @include bp($md) {
    max-width: initial;
  }
}

.l-breakout {
  --breakout-gap: 26px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, calc(100% - var(--breakout-gap))) minmax(0, 1fr);

  @include bp($lg) {
    --breakout-gap: 60px;
  }

  @include bp($xl) {
    --breakout-gap: 90px;
  }
}

.l-breakout__content {
  grid-column: 2 / -1;

  @include bp($tablet) {
    grid-column: 2 / 3;
  }

}

.deco-01 {
  position: relative;
  padding-left: 26px;

  &::before {
    position: absolute;
    height: 100%;
    width: 2px;
    background: $green;
    content: " ";
    left: 0;
  }
}

.bg-none {
  background: none !important;
}

.container {
  &.container-large {
    @include bp($xxl) {
      max-width: 1240px !important;
    }
  }

  &.container-extra {
    @include bp($xxl) {
      max-width: calc(100% - 240px) !important;
    }
  }
}

.border-light-green {
  border-color: #9FBE9E !important;
}

.z-index--1 {
  z-index: -1;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.btn.btn-outline-secondary {
  i.bi.bi-chevron-right::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 13'%3E%3Cpolygon fill='%2355814a' points='1.45 13 0 11.56 5.1 6.5 0 1.44 1.45 0 8 6.5 1.45 13'%3E%3C/polygon%3E%3C/svg%3E");
  }

  &:hover,
  &:active,
  &:focus {
    i.bi.bi-chevron-right::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 13'%3E%3Cpolygon fill='%23fff' points='1.45 13 0 11.56 5.1 6.5 0 1.44 1.45 0 8 6.5 1.45 13'%3E%3C/polygon%3E%3C/svg%3E");
    }
  }
}

.bg-light-blue {
  background-color: #cbe2e7;
}

.bg-dots-blue {
  background-size: 10px 10px;
  background-repeat: repeat repeat;
  background-image: url("../assets/img/circles-background.svg");
  background-position: 0 0;
}

.bg-dots-secondary-3-1 {
  background-image: url("../assets/img/circles-background-secondary-3-1.svg");
  background-position: 0 0;
  background-size: 10px 10px;
  background-repeat: repeat repeat;
}

.bg-dots-primary {
  background-image: url("../assets/img/circles-background-primary.svg");
  background-position: 0 0;
  background-size: 10px 10px;
  background-repeat: repeat repeat;
}

.bg-dots-primary-2-1 {
  background-image: url("../assets/img/circles-background-primary-2-1.svg");
  background-position: 0 0;
  background-size: 10px 10px;
  background-repeat: repeat repeat;
}

.bg-dots-primary-4-7 {
  background-image: url("../assets/img/circles-background-primary-4-7.svg");
  background-position: 0 0;
  background-size: 10px 10px;
  background-repeat: repeat repeat;
}

.bg-dots-secondary {
  background-image: url("../assets/img/circles-background-secondary.svg");
  background-position: 0 0;
  background-size: 10px 10px;
  background-repeat: repeat repeat;
}

.bg-dots-secondary-2-3 {
  background-image: url("../assets/img/circles-background-secondary-2-3.svg");
  background-position: 0 0;
  background-size: 10px 10px;
  background-repeat: repeat repeat;
}

.bg-dots-primary-2-6 {
  background-image: url("../assets/img/circles-background-primary-2-6.svg");
  background-position: 0 0;
  background-size: 10px 10px;
  background-repeat: repeat repeat;
}

.bg-dots-primary-3-6 {
  background-image: url("../assets/img/circles-background-primary-3-6.svg");
  background-position: 0 0;
  background-size: 10px 10px;
  background-repeat: repeat repeat;
}

.circle {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    top: -26.5px;
    left: calc(50% - 26.5px);
    background-color: $white;
    border-radius: 100%;
    width: 53px;
    height: 53px;
  }
}

.aspect-ratio-16-9 {
  aspect-ratio: 16 / 9;
  object-fit: cover;
  object-position: center center;
}

.aspect-ratio-16-9-5 {
  aspect-ratio: 16 / 9.5;
  object-fit: cover;
  object-position: center center;
}

.aspect-ratio-16-10 {
  aspect-ratio: 16 / 10;
  object-fit: cover;
  object-position: center center;
}

.aspect-ratio-4-3 {
  aspect-ratio: 4 / 3;
  object-fit: cover;
  object-position: center center;
}

.aspect-ratio-1-1 {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  object-position: center center;
}

.bg-primary-2-6 {
  .c-button-2 {
    &:hover,
    &:active,
    &:focus {
      background-color: #f5e6d1;
    }
  }
}

button,
button:focus:not(:focus-visible) {
  outline: 0 !important;
}

.ginput_container.ginput_recaptcha,
#input_3_13,
#field_3_13 {
  display: inline-flex;
}

.container-fluid.flexi-bonus-table-block table>tbody>tr:nth-last-child(1) {
  background: #D0D1D7;
}

.page.contact {
  .l-stack-sm p {
    --stack-space: 0.5rem !important;
  }
}

.row.fp-header__row {
  z-index: 11;
}

.flexi-two-thirds-one-third-block.bg-pattern-01 .flexi-two-thirds-one-third-block__sm-svg {
  z-index: 11;
}

.navbar-brand {
  z-index: 10;
}

body.page.about-us footer#colophon {
  padding-top: 12rem;
}

@include bp(0 $md) {
  img.footer-img {
    width: 80%;
    margin-top: 1.25rem;
  }
  .svg-01 {
    margin-top: -20px;
    margin-bottom: -60px;
  }
}


.col-48.col-md-21.col-xl.d-none.d-md-block.mw-xl-600.order-1.order-md-2.order-xl-3.text-white.aos-init.aos-animate {
  @include bp($md $xl) {
    margin-top: 60px;
    width: 100%;
  }
}


/* = 404 PAGE
----------------------------------------------- */

.error404,
.error404 .site {
  min-height: 100vh;
}

.error404 .site {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
}

.error404 .site-content {
  flex-grow: 1;
}



/* = PAGINATION
----------------------------------------------- */
.pagination{
  display: flex;
  justify-content: center;
  ul{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap:wrap;

    li{
      list-style: none;
      margin: 0 8px;

      .current{
        font-weight: 700;
        color: var(--color-primary-3-1);
      }
      
      a{
        padding: 0 6px;
      }
      
      a.prev{
        width:40px;
        height:40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-accent-200);
        border:1px solid  var(--color-primary-3-1);
        border-radius: 50%;
        transition: transform .4s ease-in-out;
        color: var(--color-primary-3-1);

        &:hover{
          background-color: var(--color-primary-3-1);
          color: var(--color-white);

          i{
            color: var(--color-white);
            filter: brightness(0) invert(1);
          }
        }

        i{
          color: var(--color-primary-3-1);
          &::before{
            transform: rotate(-360deg);
            transform-origin: center;

          }
        }
      }

      a.next{
        width:40px;
        height:40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-accent-200);
        border:1px solid  var(--color-primary-3-1);
        border-radius: 50%;
        transition: transform .4s ease-in-out;
        color: var(--color-primary-3-1);

        i{
          color: var(--color-primary-3-1);
        }

        &:hover{
          background-color: var(--color-primary-3-1);
          color: var(--color-white);

          i{
            color: var(--color-white);
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }
}