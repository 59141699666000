body {
  &.about-us {
    .flexi-two-col-image-heading-text-block {
      @include bp(0 $md) {
        background-color: $primary-4-5 !important;
      }
    }
    .flexi-fullwidth-svg-content-block {
      padding-bottom: 0 !important;
    }
    .flexi-fullwidth-svg-content-block+.flexi-cta-block {
      .f-body-7 {
        font-size: 12px !important;
        @include bp($md) {
          font-size: 16px !important;
        }
        @include bp($xl) {
          font-size: 20px !important;
        }
      }
      background-image: url('../assets/img/circles-background.svg') !important;
      background-color: $light-blue !important;
      padding-block: 0 !important;
      >.container {
        transform: translate3d(0, 50%, 0);
      }
    }

    .flexi-fullwidth-svg-content-block+.flexi-cta-block+.flexi-cta-block__lower--final {
      padding-bottom: 15rem !important;
    }
    .flexi-cta-block.flexi-cta-block--final {
      .c-button-2 {
        &:hover,
        &:active,
        &:focus {
          background: #F1F2F2;
        }
      }
    }
  }

}

