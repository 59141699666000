// dots: #d9e5d8
// bg:   #eff4ee

.flexi-split-ticket-block {
  background-color: $primary-3-6;
}


.flexi-split-ticket-block__feature-wrapper {
  position: relative;
  border: 1px solid $green;
  border-right-width: 1px;
  border-top-width: 0;
  @include bp($lg) {
    border-right-width: 0;
    border-top-width: 1px;
  }
  &::before {
    content: " ";
    background: #eff4ee;
    position: absolute;
    left: 0;
    height: 42px;
    width: 1px;
    z-index: 999999;
    top: calc(50% - 21px);
  }
}

.flexi-split-ticket-block__feature-col {
  order: 2;
  @include bp($lg) {
    order: initial;
  }
  position: relative;
  overflow: hidden;
  &::before {
    border: 1px solid #60935e;
    border-radius: 50%;
    content: " ";
    height: 42px;
    position: absolute;
    width: 42px;
    left: -21px;
    z-index: 999;
    background: #eff4ee;
  }
}

.flexi-split-ticket-block__image-col {
  img {
    height: 100%;
    object-fit: cover;
  }
}
