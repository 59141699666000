.flexi-media-feature-block__center {
  background-image: url("../assets/img/icons/deco-03.svg");
  background-position: bottom left;
  background-repeat: repeat-x;
}

.flexi-media-feature-block__lower--col {
  @include bp($lg) {
    margin-top: -22.5rem;
  }
}

.flexi-media-feature-block {
  .plyr__poster {
    background-color: #dbecef;
  }
  &.flexi-media-feature-block__lower {
    @include bp(0 $lg) {
      >.container {
        max-width: 100% !important;
        width: 100% !important;
      }
    }
  }
}
