.flexi-accommodation-block {
  blockquote {
    p {
      display: flex;
      i {
        display: inline-block;
        margin-left: auto;
      }
      a {
        margin-left: 6px;
      }
    }
    border: 1px solid $green;
    text-transform: uppercase;
    @include font-size(16);
    font-family: $font-family-inter;
    letter-spacing: 0.1em;
    font-weight: 700;
    line-height: (22/16);
    color: $primary;
    padding: 21px 24px 20px;
  }
  .box-title {
    text-transform: uppercase;
    @include font-size(16);
    font-family: $font-family-inter;
    letter-spacing: 0.1em;
    font-weight: 700;
    line-height: (22/16);
    margin-bottom: 10px;
    color: $primary;
  }
  .box-phone {
    @include font-size(16);
    font-family: $font-family-inter;
    letter-spacing: 0.0125em;
    line-height: (25/16);
    color: $primary;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .box-website {
    @include font-size(12);
    font-family: $font-family-inter;
    letter-spacing: 0.075em;
    line-height: (25/12);
    color: $primary;
    font-weight: 400;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  blockquote a,
  .box-website a {
    text-decoration-color: currentColor;
    &:hover,
    &:active,
    &:focus {
      text-decoration-color: transparent;
    }
  }
}

.flexi-accommodation-block__img {
  aspect-ratio: 1200 / 620;
  max-height: 720px;
  object-fit: cover;
  object-position: center;
}

.flexi-accommodation-block__col {
  margin-bottom: 24px;
  @include bp($lg) {
    margin-bottom: 0;
  }
  padding: 19px 29px;
  @include bp($lg) {
    padding: 27px 41px;
  }
  border: 1px solid $green;
  margin-top: -1px;
  margin-left: -1px;
  min-width: 25%;
  > p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
