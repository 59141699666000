.modal {
  .modal-header,
  .modal-footer {
    border: 0;
  }
  .modal-body,
  .modal-footer {
    padding-inline: 2rem;
  }
  @include bp($md) {
    .modal-body,
    .modal-footer {
      padding-inline: 3rem;
    }
  }
  @include bp($lg) {
    --bs-modal-width: 640px;
    .modal-body,
    .modal-footer {
      padding-inline: 4.5rem;
    }
  }
}
