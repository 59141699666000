/*------------------------------------*\
	# TABLE OF CONTENTS
\*------------------------------------*/


/* = BOOTSTRAP and VARIABLES
----------------------------------------------- */

/* = CONFIGURATION
..................................*/

/* = FUNCTIONS and MIXINS
=========*/

@import "bootstrap/scss/functions";
@import "variables-settings/bootstrap-functions-clobber";
@import "bootstrap/scss/mixins";
@import "bootstrap-icons/font/bootstrap-icons.scss";

/* = VARIABLES and MAPS
=========*/
@import "variables-settings/variables-settings-master"; // WAMMCOs variables
@import "variables-settings/color-register";
@import "variables-settings/bootstrap-settings"; // Bootstrap Overrides (Colors above)
// @import "variables-settings/color-contrast"; // https://codepen.io/anon/pen/jEpzRQ?editors=0100


/* = BASELINES
----------------------------------------------- */

// Useful mixins like clearfix.
@import "mixins/mixins-master";





// Let's get the basics down for elements and components.
@import "base/elements/elements-master";

// All of the typography is handled here.
@import "base/typography/typography-master";

// Accesibility.
@import "base/accessibility";

// Layout Stack
@import "layout/stack";

// Layout Tools
@import "layout/layout-tools";








// A composition of components from the base.
@import "modules/site/site-master";

// Module for navigation, main-navigation, menus, sidebar menus
@import "modules/navigation/navigation-master";
@import "modules/helpers";

// Buttons
@import "modules/buttons";
@import "modules/page-header";

// Icons
@import "modules/icons";

// No Image
@import "modules/no-image";

// Plyr Video Player
@import "../../node_modules/plyr/src/sass/plyr.scss";

// Swiper Slider
@import "../../node_modules/swiper/swiper-bundle.min.css";

// AOS plus custom easing (see https://easings.net/#easeOutQuart)
$transition-ease-out-quart: cubic-bezier(0.25, 1, 0.5, 1);
$aos-distance: 2rem !default; // It has to be above import

@import "../../node_modules/aos/src/sass/aos.scss";

// Flexi
@import "modules/flexi-video-block";
@import "modules/flexi-two-col-image-text-block";
@import "modules/flexi-two-col-image-heading-text-block";
@import "modules/flexi-timeline-block";
@import "modules/flexi-image-block";
@import "modules/flexi-multi-detail-image-block";
@import "modules/flexi-certifications-logos-block";
@import "modules/flexi-certifications-block";
@import "modules/flexi-accreditations-block";
@import "modules/flexi-two-col-list-image-block";
@import "modules/flexi-three-col-bullets-block";
@import "modules/svg-maps";
@import "modules/flexi-table-block";
@import "modules/flexi-bonus-table-block";
@import "modules/flexi-award-block";
@import "modules/flexi-vertical-table-block";
@import "modules/flexi-two-thirds-one-third-block";
@import "modules/flexi-brands-block";
@import "modules/flexi-sites-block";
@import "modules/flexi-cta-block";
@import "modules/flexi-form-block";
@import "modules/flexi-vacancies-block";
@import "modules/flexi-three-col-slider-block";
@import "modules/flexi-one-col-slider-block";
@import "modules/flexi-accommodation-block";
@import "modules/flexi-post-card-deck";
@import "modules/flexi-product-card-deck";
@import "modules/flexi-fw-form-block";
@import "modules/flexi-media-feature-block";
@import "modules/flexi-yet-another-one-off-section";
@import "modules/flexi-faqs-block";
@import "modules/flexi-section-rules";
@import "modules/flexi-timeline-more-block";
@import "modules/flexi-split-ticket-block";
@import "modules/tech-specs";
@import "modules/vacancies";

// Tables
@import "modules/tables";

// Swiper
@import "modules/swiper";

// Plyr
@import "modules/plyr";

// Modal
@import "modules/modal";

// Footer
@import "modules/footer";

// Alignments for WYSIWYG aligning
@import "layout/alignments";

// Modules for managing collective images. This is also often used in the WYSIWYG
@import "modules/media/media-master";

// Base form management.
@import "base/forms/forms-master";

// Layouts, it's final
@import "layout/layout-master";


// Utilites Master
@import "utilities/utilities-master";

// Mobile Navigation
@import "modules/navigation/mobile-navigation";

// Shame Shame Shame
@import "shame";
