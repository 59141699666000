.flexi-news-single-block {
 
  .breadcrumb{
    margin: 0;
    li{
      list-style: none;
      color: $primary;

      &::before{
        content:""
      }

      a{
        text-decoration: underline;
        text-decoration-color: currentColor;
      }
    }
  }

  .breadcrumb-main{
    margin-bottom: 64px;
  }

  .signle-title{
    h2{
      @include font-size(21);
      line-height: (25/21);
      font-family: $font-family-roboto-slab;
      letter-spacing: 0.02em;

      @include bp($md) {
        @include font-size(24);
        line-height: (26/24);
      }

      @include bp($lg) {
        @include font-size(42);
        line-height: (50/42);
      }
    }
  }

  .news-date{
    @include font-size(16);
    
    @include bp($md) {
      @include font-size(20);
    }

    strong{
      font-weight: 600;
    }
  }

  .news-img{
    margin-top: --spacer-4;
  }

  .card-title {
    font-family: $font-family-roboto-slab;
    line-height: (20/13);
    letter-spacing: 0.0125em;
    font-weight: 400;
    color: $primary; 
    margin-top: auto;
  }

  .news-category{
    @include font-size(12);
    text-transform: uppercase;
    color: var(--color-green);
    font-weight: 700;
  }
  
  .news-block{
    .card-image{
      overflow: hidden;
      aspect-ratio: 350/200;
  
      img {
        -webkit-transition: 0.4s ease-in-out all;
        transition: 0.4s ease-in-out all;
      }
    }

    &:hover{
      .card-image{
        img {
          -webkit-transform: scale(1.05);
          transform: scale(1.05);
        }
      }
    }
  }
}
