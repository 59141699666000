.flexi-timeline-more-block {
    .timeline-container {
        overflow: hidden;
        .timeline-item {
            overflow: hidden;
            .date-line {
                .date {
                    z-index: 2;
                    &:before {
                        content: " ";
                        position: absolute;
                        width: 9px;
                        height: 9px;
                        background: $primary;
                        border-radius: 50%;
                        right: calc(50% - 5px);
                        bottom: -5px;
                        @include bp($md) {
                            width: 13px;
                            height: 13px;
                            right: calc(50% - 7px);
                            bottom: -7px;
                        }
                    }
                }
                &:after {
                    content: " ";
                    position: absolute;
                    width: 1px;
                    right: calc(50% - 1px);
                    top: 0;
                    bottom: 0;
                    background-color: $primary;
                    z-index: 1;
                    @include bp($md) {
                        top: 0;
                        bottom: 0;
                    }
                }
            }
            .date-line-md {
                &:after {
                    @include bp($md) {
                        top: 33px;
                    }
                }
            }
        }
        .timeline-heading {
            text-transform: uppercase;
            transform: rotate(-3deg);
            @include bp($md) {
                transform: rotate(-3deg) translateY(-13px) translatex(-20px);
            }
            @include bp($lg) {
                transform: rotate(-3deg) translateY(-13px) translatex(-25px);
            }
        }
    }
    .timeline-button-container {
        @include bp($xs $md) {
            &:before {
                content: " ";
                position: absolute;
                width: 1px;
                right: calc(50% - 1px);
                top: 0;
                bottom: 44px;
                background-color: $primary;
            }
        }
        .timeline-button {
            text-transform: uppercase;
        }
    }
}
