#primary.single-vacancy {
  .gfield_label {
    @include font-size(13);
    font-weight: 500;
    letter-spacing: 0.0125em;
    line-height: (20/13);
    color: $primary;
  }
  .gform_wrapper {
    margin-inline: -10px;
  }
  .gfield {
    padding-inline: 10px;
    &.gfield--type-captcha {
      padding-inline: 0;
    }
  }
}

.single-vacancy__oswald-heading {
  padding: 12px 32px 16px;
  font-family: $font-family-oswald;
  color: #fff;
  text-transform: uppercase;
  @include font-size(25);
}

.single-vacancy__col {
  border: 1px solid $td-border-color;
  padding: 50px 35px 72px 50px;
}

.single-vacancy__location {
  font-family: $font-family-inter;
  line-height: (22/16);
  letter-spacing: 0.1em;
  color: $primary-2-1;
  @include font-size(16);
  text-transform: uppercase;
  font-weight: 700;
}

.single-vacancy__form-col {
  padding: 50px;
}
