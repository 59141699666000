.flexi-three-col-slider-row {
    overflow: hidden;
}

.flexi-three-col-slider-item {
    position: absolute;
    @include bp($md) {
        width: calc((100% + 34px) / 3);
    }
}

.flexi-three-col-slider-item__padding {
    padding: 0 14%;
    @include bp($md) {
        padding: 0 34px 0 0;
    }
}

.flexi-three-col-slider-item__body {
    border: 1px solid $primary-3-1;
}

.flexi-three-col-slider-item__body:after {
    position: absolute;
    content: " ";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 26px;
    background-image: url(../assets/img/lines-background-primary-3-1.svg);
    background-size: 1920px 24px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.flexi-three-col-slider-item__heading,
.flexi-three-col-slider-item__content {
    transition: opacity 0.25s ease-in-out;
    opacity: 1;
}

.flexi-three-col-slider-item--has-opacity {
    .flexi-three-col-slider-item__heading,
    .flexi-three-col-slider-item__content {
        opacity: 0.5;
    }
}

.flexi-three-col-slider-block__img {
    aspect-ratio: 16 / 9.6;
    object-fit: cover;
    object-position: center;
    width: 100%;
    display: block;
    max-width: 100%;
    height: auto;
}

.c-custom-1 {
    background-color: initial;
    right: -26px;
    top: calc(50% - 52px);
    margin: 0;
    width: 52px;
    height: 52px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: right 0.5s ease-in-out;
    outline: none;
    border: none;
    @include bp($md) {
        right: -26px;
    }
    .icon {
        width: 9px;
        height: 15px;
        position: absolute;
        z-index: 1;
        svg path,
        path {
            fill: $white;
            transition: fill 0.4s ease-in-out;
        }
    }
    &::after {
        content: " ";
        display: block;
        width: 100%;
        height: 100%;
        background-color: $green;
        border-radius: 50%;
        transition: transform 0.4s ease-in-out;
        position: absolute;
    }
    &:hover {
        svg path,
        path {
            fill: $primary-1-5;
        }
        &::after {
            transform: scale(0.95);
        }
    }
    > * {
        opacity: 1;
    }
}
