/*------------------------------------*\
	# MENUS
\*------------------------------------*/

.main-navigation {
	display: block;
	width: 100%;

	ul {
		list-style: none;
		margin: 0;
		padding-left: 0;
	}

	ul ul {
		box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
		float: left;
		position: absolute;
		top: 1.5em;
		left: -999em;
		z-index: 99999;
	}

	ul li:hover > ul {
		left: auto;
	}

	ul ul ul {
		left: -999em;
		top: 0;
	}

	ul ul li:hover > ul {
		left: 100%;
	}

	ul ul a {
		width: 200px;
	}

	li {
		position: relative;
	}

	li:hover > a {
		text-decoration: underline;
	}


	a {
		display: block;
		text-decoration: none;
		padding: 0 10px;
	}

	.current_page_item > a,
	.current-menu-item > a,
	.current_page_ancestor > a {
		background: color__(primary);
		color: color__(white);
		padding: 0 10px;
	}

}

/* Small menu */
.menu-toggle {
	display: none;
}

@media screen and (max-width: 600px) {
	.menu-toggle,
	.main-navigation.toggled .nav-menu {
		display: block;
	}

	.main-navigation ul {
		display: none;
	}
}
