.flexi-sites-block {
    .site {
        @include bp($lg) {
            max-width: 50%;
        }
    }
    .site ~ .site {
        @include bp($lg) {
            &:nth-child(2) {
                padding-top: 0;
            }
        }
    }
    .cardinal-site {
        border-radius: 50%;
    }
}
