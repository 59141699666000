/*------------------------------------*\
	# TYPOGRAPHY MASTER
\*------------------------------------*/

body,
button,
input,
select,
textarea {
	color: $primary;
	font-family: $font__main;
}


@import "headings";
@import "body";
@import "links";
@import "lists";
