.site-footer {
  a {
    line-height: inherit;
    letter-spacing: inherit;
    color: $white;
    transition: text-decoration-color 0.15s $transition-main;
    text-decoration-line: underline;
    text-decoration-color: transparent;
    &:hover,
    &:focus,
    &:active {
      color: color__(link-hover);
      text-decoration-color: currentColor;
    }
  }
  .active {
    > a {
      color: $white;
      text-decoration-color: $white;
    }
  }
  a[target=socials] {
    display: flex;
    width: fit-content;
    position: relative;
    left: -16px;
  }
  .hr__footer {
    @include bp($xxl) {
      margin-left: -3rem;
      margin-right: -3rem;
    }
  }
  img {
    max-width: 154px;
  }
}

.nav-footer {
  .menu-item {
    > a {
      line-height: 2;
    }
  }
}

.btn {
  width: fit-content;
  &.btn-scrollup {
    border: 1px solid $td-border-color;
    padding: 6px;
    line-height: 1;
  }
}
