/*------------------------------------*\
	# LINKS
\*------------------------------------*/

a {
	color: color__(link);
	transition:
		color .15s $transition-main,
		text-decoration-color .15s $transition-main;
	text-decoration-line: underline;
	text-decoration-color: transparent;
	&:hover,
	&:focus,
	&:active {
		color: color__(link-hover);
		text-decoration-color: currentColor;
	}
	> * {
		text-decoration-color: inherit;
	}
}
