.flexi-image-block {
    img {
        object-fit: cover;
        object-position: center;
        max-height: 652px;
        width: 100%;
        aspect-ratio: 16 / 9;
        @include bp($lg) {
            aspect-ratio: 1400 / 652;
        }
        @include bp($xl) {
            aspect-ratio: 1900 / 752;
            max-height: 752px;
        }
    }
}
