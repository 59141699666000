/*------------------------------------*\
	# SITE HEADER
\*------------------------------------*/

.site-header {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  nav {
    width: 100%;
  }
}