.flexi-accreditations-block {
    .dots {
        >div {
            position: relative;
            &::before {
                content: " ";
                position: absolute;
                width: 7px;
                height: 7px;
                background: $primary;
                left: 0;
                top: calc(50% - 3px);
                border-radius: 50%;
            }
        }
        &::after {
            background-color: $primary;
            position: absolute;
            content: " ";
            top: 0;
            bottom: 0;
            left: 3px;
            width: 1px;
        }
    }
}
