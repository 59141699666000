.flexi-certifications-logos-block {
    img {
        width: 50%;
        object-fit: contain;
        @include bp(360px) {
            width: 33%;
        }
        @include bp($sm) {
            width: 25%;
        }
    }
}
