/*------------------------------------*\
	# LAYOUT MASTER
\*------------------------------------*/

/* = SPACERS CSS VARIABLES
@see _layout-settings.scss
----------------------------------------------- */

:root {
	@each $space_name, $space in $spacers {
		--spacer-#{""+$space_name}: #{$space};
	}
}


/* = GAPS CSS VARIABLES
@see _layout-settings.scss
----------------------------------------------- */

:root {
	@each $gap_name, $gap in $gaps {
		--#{""+$gap_name}: #{$gap};
	}
}



@import "box";
@import "cluster";
@import "split";




/*------------------------------------*\
	# SITE WIDE LAYOUTS
\*------------------------------------*/
