.flexi-post-card-deck {
  .swiper {
    
  }
  .card {
    border: 0;
  }
  .two-col-img {
    width: 100%;
  }
  .card-header {
    padding: 20px;
    @include bp($lg) {
      padding: 30px;
    }
    border-bottom: 1px solid $green;
    background-color: $accent;
  }
  .card-body {
    padding: 20px;
    @include bp($lg) {
      padding: 30px;
    }
    background-color: $accent;
  }
  .card-footer {
    padding: 15px 26px;
    background-color: $secondary-light;
    background-image: url("../assets/img/deco-04.svg");
    background-repeat: repeat repeat;
    background-position: top left;
    background-size: 350px 60px;
    border: 0;
    display: flex;
  }
}

.flexi-post-card-deck--lower__row {
  margin-top: calc((7rem + 100px) * -1);
}

.flexi-post-card-deck--upper {
  >.container {
    padding-bottom: calc(100px + 3.5rem);
    @include bp($xl) {
      padding-bottom: 3.5rem;
    }
  }
}
