.flexi-post-card-deck {
  --swiper-navigation-sides-offset: -26px;
  --swiper-navigation-size: 52px;
  --swiper-theme-color: #fff;
  --swiper-navigation-size: 14px;
  .swiper {
    .swiper-slide {
      height: auto;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    width: 52px;
    height: 52px;
    background-color: $primary;
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
    top: -42px;
    transition: transform 0.4s ease-in-out, color 0.4s ease-in-out;
    &:hover {
        transform: scale(0.95);
        color: $primary-1-5;
      }
  }
}
