.flexi-table-block {
  .accordion-button {
    font-family: $font-family-oswald;
    @include font-size(32);
    line-height: (38/32);
    letter-spacing: 0.01em;
    text-transform: uppercase;
    font-weight: 400;
    color: $primary;
    background: none;
    border-bottom: 1px solid $primary;
    box-shadow: none;
  }
  .accordion-body {
    padding: 0;
    overflow-x: scroll;
    @include bp(1330px) {
      overflow-x: auto;
    }
    &::selection {
      background: none;
      color: $primary;
    }
  }
  .table {
    min-width: 1200px;
  }
}

.accordion-collapse__table {
  position: relative;
  &::after {
    content: "";
    display: block;
    height: 100%; // Show those borders beneath!
    width: 20px;
    position: absolute;
      top: 0;
      right: 0;
    pointer-events: none;
    opacity: 1;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0, 0, 0, 0.2) 100%);
    @include bp(1330px) {
      display: none;
    }
  }
  .table-wrapper {
    position: relative;
    scrollbar-color: $td-border-color transparent;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: $td-border-color;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: $td-border-color;
    }
    *::selection {
      background: none;
      color: inherit;
    }
  }
}

.dragscroll {
  cursor: move;
  user-select: none;
  &:active {
    cursor: grabbing;
  }

  @include bp(1330px) {
    cursor: normal;
  }
}
