.flexi-more-articles-block {
 
  .more-articles-container{
    margin-left: 40px;

    @include bp($md) {
      margin-left: 50px;
    }

    @include bp($lg) {
      margin-left: 100px;
    }

    .more-articles-title{
      position: relative;
      display: flex;
      align-items: center;
      padding-right: 40px;

      .btn-block{
        width:126px;
        margin-left: auto;
        position: relative;
        display: flex;
        gap: 10px;
        flex-direction: row-reverse;

        .swiper-button-next{
          margin-left:5px;
        }
        .c-custom-1 {
          left: 0;
          right:0;
          position: relative;
          width:40px;
          height:40px;

          @include bp($md) {
            width:52px;
            height:52px;
          }

          &::after{
            font-size: 16px;
            color: var(--color-white);
            text-align: center;
            line-height: 40px;
            font-weight: 700;
            content:"prev";

             @include bp($md) {
              line-height: 52px;
            }
          }
        }
        .swiper-button-prev{
          &::after{
            content:"next";
          }
        }
      }
    }
  }


  .flexi-news-list-block {
 
    .row > *{
      margin-top: 54px;
    }
    
    .card-body {
      padding: 18px 20px 18px 20px;
      min-height:176px;
      display: flex;
      flex-direction: column;
    }

    .card-footer {
      background-image: url("../assets/img/deco-03.svg");
      background-repeat: repeat repeat;
      background-position: top left;
      background-size: 350px 60px;
  
      .btn{
        box-sizing: border-box;
        i.bi-arrow-bar-down{
          &::before{
            content:"";
            background: url("../assets/img/icons/download-icon.svg");
            width:18px;
            height:22px;
            background-size: 100%;
          }
        }

        i.bi-arrow-right-short{
          &::before{
            content:"";
            background: url("../assets/img/icons/arrow-top-right.svg");
            width:18px;
            height:18px;
            background-size: 100%;
            top:-4px;
            position: relative;
            left:4px;
          }
        }
      }
    }

    .card-title {
      font-family: $font-family-roboto-slab;
      line-height: 1.3;
      letter-spacing: 0.0125em;
      font-weight: 400;
      color: $primary; 
      margin-top: auto;
    }

    .news-category{
      @include font-size(12);
      text-transform: uppercase;
      color: var(--color-green);
      font-weight: 700;
    }
  
    .news-block{
      .card-image{
        overflow: hidden;
        aspect-ratio: 350/200;
    
        img {
          -webkit-transition: 0.4s ease-in-out all;
          transition: 0.4s ease-in-out all;
        }
      }
  
      &:hover{
        .card-image{
          img {
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
          }
        }
      }
    }
  }
}
