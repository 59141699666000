/*------------------------------------*\
  # TECH SPECS
\*------------------------------------*/

.c-tech-specs {
	display: grid;
	gap: 50px;

	@include bp($tablet) {
		grid-template-columns: minmax(0, 324fr) minmax(0, 708fr);
	}

}

.c-tech-specs__title {
	grid-column: 1/-1;
}

.c-tech-specs__diagram {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 40px;
}

[data-lamb-region] {
	opacity: 0;
	transition: 0.2s ease-in-out all;
}

[data-lamb-region].show,
.show>[data-lamb-region] {
	opacity: 1;
}

.show--all-regions {
  g[data-lamb-region].show>path,
  path[data-lamb-region].show {
    fill-opacity: 0.65;
  }
}

g[data-lamb-region].show>path,
path[data-lamb-region].show {
	opacity: 1;
  fill: #60935e;
  fill-opacity: 0.65;
}

g[data-lamb-region]>path,
path[data-lamb-region] {
  fill-opacity: 0.65;
  stroke-miterlimit: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}


/* = REGIONS
----------------------------------------------- */

.c-regions > ul,
.c-cuts__region > ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

.c-regions__item,
.c-cuts__item {
	background: transparent url(../assets/img/border-3px-dashed.png) repeat-x 0 100%;
}

.c-regions__item-trigger,
.c-cuts__item-trigger {
	padding: 10px 13px;
	padding-left: 0;
	width: 100%;
	display: flex;
	gap: 40px;
	justify-content: space-between;
	align-items: center;
	background-color: transparent;
	transition: 0.2s ease-in-out all;
	border-bottom: 1px solid transparent;
}

.c-regions__item-label,
.c-cuts__item-label {
	@include font-size(20);
	font-weight: 500;
	letter-spacing: 0.020em;
	transition: 0.2s ease-in-out transform;
	transform: translateX(0);
}


.c-cuts__item-label {
	@include font-size(16);
}

.c-regions__item-icon {
	height: 12px;
	width: 12px;
}


// HOVER / ACTIVE
.c-regions__item-trigger:hover,
.c-regions__item-trigger.active,
.c-cuts__item-trigger:hover,
.c-cuts__item-trigger[aria-expanded="true"] {
	background-color: var(--color-accent-200);
	color: var(--color-primary-3-1);
	border-bottom: 1px solid var(--color-primary-3-1);

	.c-regions__item-label,
	.c-cuts__item-label {
		transform: translateX(13px);
	}

}



.c-regions__item-trigger:hover .c-regions__item-icon path,
.c-regions__item-trigger.active path {
	stroke: var(--color-primary-3-1);
}



/* = CUTS (COLLAPSE)
----------------------------------------------- */

.c-cuts__item-trigger {
	position: relative;
	padding-right: 60px;
}

.c-cuts__item:has([aria-expanded="true"]) {
	margin-bottom: 20px;
}


/* = COLLAPSE TOGGLE
..................................*/

.c-cuts__item-trigger-icon {
	width: 14px;
	height: 14px;
	display: block;
	position: absolute;
	top: calc(50% - 7px);
	right: 13px;
}

.c-cuts__item-trigger-icon::before,
.c-cuts__item-trigger-icon::after {
	content: '';
	display: block;
	width: 100%;
	height: 2px;
	background-color: currentColor;
	position: absolute;
	top: 50%;
	left: 0;
	transition: 0.2s ease-in-out transform;
}

.c-cuts__item-trigger-icon::after {
	transform-origin: center;
	transform: rotate(90deg);
}

.c-cuts__item-trigger[aria-expanded="true"] .c-cuts__item-trigger-icon::after {
	transform: rotate(0deg);
}


/* = COLLAPSE CONTENT
..................................*/

.c-cuts__item-content {
	display: grid;

	@include bp($tablet) {
		grid-template-columns: minmax(0, 440fr) minmax(0, 268fr);
		gap: 0;
	}

}

.c-cuts__item-content-title {
	@include font-size(14);
	font-weight: 700;
	letter-spacing: 0.100em;
	text-transform: uppercase;
}


[class*="c-cuts__item-area-"] {
	border-bottom: 1px solid var(--color-primary-3-1);
	padding: 24px 0;
	@include font-size(14);
}

/* = IMAGE
=========*/

.c-cuts__item-area-1 {
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 30px;

	@include bp($tablet) {
		grid-column-start: 2;
		grid-row: 1/3;
		border-left: 1px solid var(--color-primary-3-1);
	}

}

.c-cuts__item-area-1 img {
	height: auto;
	width: auto;
	max-height: 284px;
	max-width: 284px;

	@include bp($tablet) {
		max-height: 200px;
	}

}

.c-cuts__item-area-2,
.c-cuts__item-area-3 {

	@include bp($tablet) {
		grid-column-start: 1;

		padding-right: 60px;
	}

}

.c-cuts__item-area-2 {

	@include bp($tablet) {
		grid-row-start: 1;
	}

}

.c-cuts__item-area-3 {

	@include bp($tablet) {
		grid-row-start: 2;
	}

}
