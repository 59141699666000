.flexi-colored-cta-block {
  a {
    text-decoration-color: currentColor;
    &:hover,
    &:active,
    &:focus {
      text-decoration-color: transparent;
    }
  }
}

// .flexi-colored-cta-block__row {
//   padding: 30px 45px;
//   @include bp($lg) {
//     padding: 45px 80px;
//   }
// }
