/*------------------------------------*\
  # LOADING BACKGROUND
  - Elegant loading background gradient
\*------------------------------------*/

.bg-loading {
  background: color__(primary);
  background-image: url("../assets/img/icons/icon-loading.svg");
  background-size: 35px;
  background-repeat: no-repeat;
  background-position: center center;
}
