.site-header {
  background-color: $primary-4-5;
  >.navbar  {
    border-bottom: 1px solid $td-border-color;
    @include bp($lg) {
      border-bottom: 0;
    }
  }
}

.navbar-toggler {
  padding: 2rem 2rem;
  border: 0;
  background: $primary-4-7;
  border-left: 1px solid $td-border-color;
}

.navbar-brand {
  transform: translate3d(0, 0, 0);
  height: 48px;
  @include bp($lg) {
    transform: translate3d(0, 19px, 0);
    height: 95px;
  }
  @include bp($xl) {
    transform: translate3d(0, 30px, 0);
  }
}

.navbar__logo-img {
  display: none;

  @include bp($lg) {
    display: block;
    max-width: 105px;
    margin-bottom: -50%;
  }
  @include bp($xl) {
    // margin-bottom: calc(-50% - 30px);
    margin-bottom: -50%;
    max-width: 164px;
  }
}

.navbar-nav {
  --bs-nav-link-font-size: 18px;
  @include bp($lg) {
    --bs-nav-link-font-size: 12px;
  }
  @include bp($xl) {
    --bs-nav-link-font-size: 16px;
  }
  &.nav-primary,
  &.nav-top-links {
    .menu-item {
      @include font-size(18);
      line-height: (21/12);
      text-transform: uppercase;
      font-family: $font-family-oswald;
      letter-spacing: 0.0125em;
      font-weight: 400;
      color: $primary;

      @include bp($lg) {
        @include font-size(16);
        line-height: (33/16);
      }

      button.nav-link {
        @include font-size(18);
        line-height: (21/12);
        text-transform: uppercase;
        font-family: $font-family-oswald;
        letter-spacing: 0.0125em;
        font-weight: 400;
        color: $primary;

        @include bp($lg) {
          @include font-size(16);
          line-height: (33/16);
        }
      }

      .nav-link {
        color: $primary;
        transition: color 0.15s $transition-main;
      }

      &:hover,
      &:active,
      &:focus,
      &.active {

        .nav-link {
          color: lighten($primary, 5%);
          background: initial;
        }
      }
    }
  }

  &.nav-primary {

    width: 100%;
    justify-content: space-evenly;
    >.menu-item {
      >.nav-link {
        position: relative;
        @include font-size(18);
        @include bp($lg) {
          @include font-size(12);
        }
        @include bp($xl) {
          @include font-size(16);
        }
      }
    }
  }

  &.nav-top-links {
    >.menu-item {
      >.nav-link {
        padding: 11px 16px;
        @include font-size(18);
        @include bp($lg) {
          @include font-size(12);
          padding: 10px 11px;
        }
        @include bp($xl) {
          padding: 14px;
          @include font-size(16);
        }
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        >svg {
          margin-left: 0.5rem;
          @include bp($xl) {
            margin-left: 1rem;
          }
          display: inline-block;
        }
      }

      background-color: $green;

      >.nav-link {
        color: #fff;
      }
    }
  }
}

.navbar-top-links {
  min-width: initial;
  @include bp($lg) {
    min-width: 138px;
  }
  @include bp($xl) {
    min-width: 224px;
  }
  >ul {
    width: 100%;
  }
}
