.flexi-one-col-slider-block {
    .carousel {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: flex-start;
        background-image: url("../assets/img/circles-background-secondary-1-2.svg");

        .carousel-control-next,
        .carousel-control-prev {
            align-items: flex-start;
            width: 10%;
            margin-top: 84px;
            padding: 10px;
            position: static;
            transition: opacity 0.15s ease;
            opacity: 1;

            &:hover {
                opacity: 0.6;
            }
        }

        .carousel-inner {
            width: 70%;
            min-height: 310px;

            @include bp($xl) {
                min-height: 290px;
            }

            .carousel-item {
                padding-top: 60px;

                @include bp($xl) {
                    padding-top: 20px;
                }
            }

            .carousel-caption {
                position: static;
                padding-top: 60px;

                @include bp($xl) {
                    padding-top: 20px;
                }

                p+p {
                    margin-top: 0;

                    @include bp($md) {
                        margin-top: 12px;
                    }

                    @include bp($xl) {
                        margin-top: 8px;
                    }
                }
            }
        }

        .carousel-indicators {
            position: static;
            width: 100%;
            margin-bottom: 28px;

            @include bp($md) {
                margin-bottom: 70px;
            }

            [data-bs-target] {
                border-radius: 50%;
                width: 10px;
                height: 10px;
                margin: 0 5px;

                @include bp($md) {
                    width: 6px;
                    height: 6px;
                    margin: 0 3px;
                }
            }
        }

        &::before {
            position: absolute;
            content: " ";
            border: 1px solid $primary-2-4;
            background-color: $primary-2-6;
            height: 100px;
            border-radius: 50%;
            width: 100px;
            top: -75px;
            clip-path: inset(73px 7px 0 7px);
        }

        &::after {
            position: absolute;
            content: " ";
            top: -25px;
            background-image: url("../assets/img/icons/quote.svg");
            background-repeat: no-repeat;
            height: 30px;
            width: 33px;
        }
    }
}
