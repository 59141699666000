.table {
  >thead {
    >tr {
      >th {
        background: $primary;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        font-family: $font-family-inter;
        letter-spacing: 0.1em;
        vertical-align: middle;
        font-weight: 700;
        line-height: (18/13);
        @include font-size(13);
        min-height: 4.25rem;
        padding-block: 1rem;
      }
    }
  }
  >tbody {
    >tr {
      >td {
        color: $primary;
        text-align: center;
        font-family: $font-family-inter;
        letter-spacing: 0.0125em;
        vertical-align: middle;
        line-height: (25/16);
        @include font-size(16);
      }
    }
  }
  th, td {
    border: 1px solid $td-border-color;
  }
}
