$form-text-light: #9FBE9E;

.flexi-fw-form-block {
  .gform_wrapper {
    .gfield {
      padding-inline: 10px;
      &[class^=col-] {
        padding-inline: 10px;
      }
      &.gfield--type-captcha {
        @include bp($lg) {
          margin-left: auto;
        }
      }
      &.gfield--type-submit {
        @include bp($lg) {
          margin-right: 94px;
        }
      }
      &.gfield--type-checkbox {
        align-items: center;
        display: flex;
        padding-top: 20px;
      }
    }
    .gform_fields.row  {
      margin-inline: -10px;
    }
    .spacer.gfield {
      display: none !important;
    }
  }
  #field_submit {
    width: auto !important;
  }
  &.text-white {
    .gform_wrapper {
      .gfield_label {
        color: #fff;
      }
      .gfield {
        input::placeholder {
          color: $form-text-light;
        }
        input,
        select,
        select>option {
          color: $green;
        }
        &.gfield--type-submit {
          >button {
            color: #fff;
            border-color: #fff;
          }
        }
      }
      i.bi.bi-chevron-right::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 13'%3E%3Cpath fill='%23fff' d='M1.45 13 0 11.56 5.1 6.5 0 1.44 1.45 0 8 6.5z'/%3E%3C/svg%3E");
      }
    }
  }
}
