.flexi-vacancies-block {
  .card-body {
    padding: 30px 45px 40px 30px;
  }
  .card-footer {
    background-image: url("../assets/img/deco-03.svg");
    background-repeat: repeat repeat;
    background-position: top left;
    background-size: 350px 60px;
  }
  .card-text {
    font-family: $font-family-inter;
    line-height: (20/13);
    letter-spacing: 0.0125em;
    font-weight: 400;
    @include font-size(13);
    color: $primary;
  }
}
