/*------------------------------------*\
  # COLOR REGISTER
	@requires _color-settings.scss
\*------------------------------------*/

/* = CREATE COLOR FUNCTION
-----------------------------------------------

Example Usage
-------------

body {
	background-color: color__(primary-f2f);
}

----------------------------------------------- */

@function color__($color) {
	@return map-get($color-vars-all, $color);
}

/* = ADD COLORS as CSS PROPERTIES (VARIABLES)
Automatic write colors of map to css variables using a SASS loop
----------------------------------------------- */

:root {
	@each $color_name, $color in $color-vars-all {
		--color-#{""+$color-name}: #{$color};
	}
}

/* = DESIGN SYSTEM - OUTPUT
These could be further reduced to a map but I'm out of time ladies and gents!
----------------------------------------------- */

.design-system {

	/* = PALETTE
	..................................*/

	@each $color_name, $color in $color-vars-common {
		--palette-common--color-#{""+$color-name}: #{$color};
	}

	@each $color_name, $color in $color-vars-theme {
		--palette-theme--color-#{""+$color-name}: #{$color};
	}

	@each $color_name, $color in $color-vars-social {
		--palette-social--color-#{""+$color-name}: #{$color};
	}

	@each $color_name, $color in $color-vars-state {
		--palette-state--color-#{""+$color-name}: #{$color};
	}

	/* = APPLIED
	..................................*/

	@each $color_name, $color in $color-vars-applied-text {
		--applied-text--color-#{""+$color-name}: #{$color};
	}

}
