.text-left {
  text-align: left !important;
}

.text-black {
  color: #000 !important;
  *:not(video):not([class*=plyr]):not([class^=icon]) {
    color: #000;
  }
  a {
    &:hover,
    &:active,
    &:focus,
    &.active,
    &.selected {
      text-decoration-color: #000;
    }
  }
}

.text-primary {
  color: $primary !important;
  *:not(video):not([class*=plyr]):not([class^=icon]) {
    color: $primary;
  }
  a {
    &:hover,
    &:active,
    &:focus,
    &.active,
    &.selected {
      text-decoration-color: $primary;
      color: #DCECEF;
    }
  }
}

.text-white {
  color: #fff !important;
  *:not(video):not([class*=plyr]):not([class^=icon]) {
    color: #fff;
  }
  a {
    &:hover,
    &:active,
    &:focus,
    &.active,
    &.selected {
      text-decoration-color: #fff;
    }
  }
}

.text-gray,
.text-grey {
  color: $td-border-color !important;
  *:not(video):not([class*=plyr]):not([class^=icon]) {
    color: $td-border-color;
  }
  a {
    &:hover,
    &:active,
    &:focus,
    &.active,
    &.selected {
      text-decoration-color: $td-border-color;
    }
  }
}

.br-50 {
  border-radius: 50% !important;
}

.br-0 {
  border-radius: 0 !important;
}

.br-999 {
  border-radius: 999em !important;
}

.bg-none {
  background: none !important;
}

.b-0 {
  border-width: 0 !important;
}

.b-1 {
  border-width: 1px !important;
}

.b-2 {
  border-width: 2px !important;
}

.bs-0 {
  box-shadow: none !important;
}

.ls-0 {
  letter-spacing: 0 !important;
}

.ls-1 {
  letter-spacing: 0.01em !important;
}

.ls-2 {
  letter-spacing: 0.02em !important;
}

.ls-3 {
  letter-spacing: 0.03em !important;
}

.ls-4 {
  letter-spacing: 0.04em !important;
}

.ls-5 {
  letter-spacing: 0.05em !important;
}

.ls-6 {
  letter-spacing: 0.06em !important;
}

.ls-7 {
  letter-spacing: 0.07em !important;
}

.ls-8 {
  letter-spacing: 0.08em !important;
}

.ls-9 {
  letter-spacing: 0.09em !important;
}

.ls-10 {
  letter-spacing: 0.1em !important;
}
.ls-11 {
  letter-spacing: 0.11em !important;
}
.ls-12 {
  letter-spacing: 0.12em !important;
}
.ls-13 {
  letter-spacing: 0.13em !important;
}
.ls-14 {
  letter-spacing: 0.14em !important;
}
.ls-15 {
  letter-spacing: 0.15em !important;
}
.ls-16 {
  letter-spacing: 0.16em !important;
}
.ls-17 {
  letter-spacing: 0.17em !important;
}
.ls-18 {
  letter-spacing: 0.18em !important;
}
.ls-19 {
  letter-spacing: 0.19em !important;
}
.ls-20 {
  letter-spacing: 0.10em !important;
  @include bp($lg) {
    letter-spacing: 0.20em !important;
  }
}

.fw-100 {
  font-weight: 100 !important;
  &::placeholder {
    font-weight: 100 !important;
  }
}

.fw-200 {
  font-weight: 200 !important;
  &::placeholder {
    font-weight: 200 !important;
  }
}

.fw-300 {
  font-weight: 300 !important;
  &::placeholder {
    font-weight: 300 !important;
  }
}

.fw-400 {
  font-weight: 400 !important;
  &::placeholder {
    font-weight: 400 !important;
  }
}

.fw-500 {
  font-weight: 500 !important;
  &::placeholder {
    font-weight: 500 !important;
  }
}

.fw-600 {
  font-weight: 600 !important;
  &::placeholder {
    font-weight: 600 !important;
  }
}

.fw-700 {
  font-weight: 700 !important;
  &::placeholder {
    font-weight: 700 !important;
  }
}

.font-italic {
  font-style: italic !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-27 {
  font-size: 27px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-29 {
  font-size: 29px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-31 {
  font-size: 31px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-33 {
  font-size: 33px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-35 {
  font-size: 35px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-37 {
  font-size: 37px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-39 {
  font-size: 39px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-41 {
  font-size: 41px !important;
}

.fs-42 {
  font-size: 42px !important;
}

.fs-43 {
  font-size: 43px !important;
}

.fs-44 {
  font-size: 44px !important;
}

.fs-45 {
  font-size: 45px !important;
}

.fs-46 {
  font-size: 46px !important;
}

.fs-47 {
  font-size: 47px !important;
}

.fs-48 {
  font-size: 48px !important;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-clamp-4 {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.line-clamp-5 {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.line-clamp-6 {
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.line-clamp-none {
  overflow: visible;
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-line-clamp: none;
}

.text-initial {
  text-transform: initial !important;
}

.text-none {
  text-transform: none !important;
}

.text-italic {
  font-style: italic !important;
}

.bl-1 {
  border-left: 1px solid;
}

.br-1 {
   border-right: 1px solid;
}

.bt-1 {
   border-top: 1px solid;
}

.bb-1 {
   border-bottom: 1px solid;
}

.bl-2 {
 border-left: 2px solid;
}

.br-2 {
  border-right: 2px solid;
}

.bt-2 {
  border-top: 2px solid;
}

.bb-2 {
  border-bottom: 2px solid;
}

.deco-hover-line {
  position: relative;
  &::before {
    display: block;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: rgba($primary, 0);
    bottom: -1px;
    left: 0;
    transition: transform 0.15s $transition-main 0.15s, 0.15s background-color 0s linear;
    transform: translate3d(0, 0,0);
  }
  &:hover,
  &:active,
  &:focus {
    &::before {
      transform: translate3d(0, -0.25rem,0);
      background-color: rgba($primary, 1);
      transition: transform 0.15s $transition-main 0s, 0.15s background-color 0.15s linear;
    }
  }
}

.position-initial {
  position: initial !important;
}
