.flexi-timeline-block {
    .before-md-divider::before {
        @include bp($md) {
            content: "";
            position: absolute;
            top: 28px;
            left: calc(50% - 1px);
            bottom: 28px;
            width: 1px;
            background-color: $primary-2-1;
        }
    }
    .left ~ .left {
        &::before {
            content: "";
            height: 1px;
            width: 100%;
            background-color: $primary-2-1;
            margin-top: 28px;
            @include bp($md) {
                position: absolute;
                width: 125%;
                left: -5%;
                top: 48px;
                margin-top: initial;
            }
        }
        .heading::before {
            @include bp($md) {
                content: "";
                margin-top: 48px;
                width: 100%;
                display: block;
            }
        }
    }
    .item-left-container ~ .item-right-container .right,
    .right ~ .right {
        @include bp($xs $md) {
            &::before {
                content: "";
                height: 1px;
                width: 100%;
                background-color: $primary-2-1;
                margin-top: 28px;
            }
        }
    }
}
