.page-header__row {
  bottom: 0;
}

.page-header__img {
  aspect-ratio: 390 / 190;
  width: 100%;
  object-fit: cover;
  @include bp($md) {
    aspect-ratio: 904 / 314;
  }
  @include bp($xl) {
    aspect-ratio: 1402 / 421;
  }
}

.page-header__col {
  padding: 8px 20px 5px;
  @include bp($md) {
    padding: 15px 30px 10px;
  }
  @include bp($lg) {
    padding: 17px 40px 12px;
  }
  background: #fff;
}

.page-header__title {
  font-family: $font-family-roboto-slab;
  @include font-size(21);
  line-height: (25/21);
  color: $primary;
  text-align: center;
  @include bp($md) {
    @include font-size(24);
    line-height: (26/24);
  }
  @include bp($lg) {
    @include font-size(42);
    line-height: (50/42);
  }
}

.fp-header__heading {
  font-family: $font-family-roboto-slab;
  line-height: 1.25;
  font-weight: 400;
  color: #fff;
  text-align: center;
  // @include bp($md) {
  //   @include font-size(35);
  // }
  // @include bp($lg) {
  //   @include font-size(52);
  // }
  // @include bp($xl) {
  //   @include font-size(80);
  // }
  font-size: clamp(2.2974rem, 4vw + 1rem, 5.125rem);
  cursor: default;
}

.fp-header__col {
  // padding: 38px 30px;
  // @include bp($lg) {
  //   padding: 56px 58px;
  // }
  // @include bp($xl) {
  //   padding: 88px 120px;
  // }
  // margin: 24% 30% 2rem 2rem;
  margin: 4vw 6vw 4vw 6vw;
}

.fp-header__img {
  aspect-ratio: 1400 / 790;
  width: 100%;
  object-fit: cover;
  max-height: 790px;
  display: flex;
  @include bp($lg) {
    object-position: center top;
  }
}

.fp-header__img::after {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(225deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%);
  z-index: 8;
  top: 0;
  left: 0;
}
.fp-header__img {
  position: relative;
}

.fp-header__img--video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fp-header__row {
  top: 0;
  right: 0;
}
