.flexi-vertical-table-block {
  .table {
    width: 100%;
    max-width: 604px;
    &.table--vertical {
      >thead>tr>th:nth-child(1) {
        text-align: left;
      }
      >tbody>tr>td:nth-child(1) {
        text-align: left;
      }
    }
    >:not(caption)>*>* {
      padding: 0.725rem 1.125rem;
    }
  }
}
.flexi-vertical-table-block--background {
  .background {
    &.background-lines {
      height: 31px;
      width: 100%;
      background-image: url("../assets/img/lines-background.svg");
      background-size: cover;
      background-position: 50% 9px;
      background-repeat: repeat-x;
      background-size: 1920px 24px;
    }
  }
}
