.flexi-brands-block {
    .top-border ~ .top-border {
        padding-top: 90px;
        @include bp($xl) {
            padding-top: 160px;
        }
        &::before {
            position: absolute;
            content: "";
            top: 42px;
            right: 0;
            left: 0;
            height: 1px;
            background-color: $primary-2-4;
            @include bp($xl) {
                top: 80px;
            }
        }
    }
    .top-md-border ~ .top-md-border {
        @include bp($md) {
            padding-top: 90px;
            @include bp($xl) {
                padding-top: 160px;
            }
            &::before {
                position: absolute;
                content: "";
                top: 42px;
                right: 0;
                left: 0;
                height: 1px;
                background-color: $primary-2-4;
                @include bp($xl) {
                    top: 80px;
                }
            }
        }
    }
}
