.flexi-three-col-bullets-block {
    &::before {
        background-color: $primary-2-6;
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        bottom: 50%;
        left: 0;
        z-index: -1;
    }
    &::after {
        background-color: $primary-2-6;
        position: absolute;
        content: "";
        top: 50%;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        @include bp($md) {
            background-color: $white;
        }
    }
    .bullet {
        padding: 0 0 18px 64px;
        @include bp($md) {
            padding: 24px 0 0 0;
        }
        @include bp($xl) {
            padding: 28px 0 0 0;
        }
        @include bp($xs $md) {
            ~ .bullet {
                padding-top: 48px;
            }
        }
    }
    .bullet {
        > div {
            position: relative;
            &::before {
                content: " ";
                position: absolute;
                width: 13px;
                height: 13px;
                background: $primary;
                left: -38px;
                top: calc(50% - 6px);
                border-radius: 50%;
                @include bp($md) {
                    content: initial;
                }
            }
        }
        .f-body-16 {
            text-align: left;
            @include bp($md) {
              text-align: center;
            }
        }
    }
    .dots::before {
        @include bp($md) {
            content: " ";
            position: absolute;
            height: 7px;
            width: 100%;
            background-image: url("../assets/img/icons/circle.svg");
            background-size: 33% 7px;
            top: -3px;
        }
        @include bp($xl) {
            height: 13px;
            top: -6px;
            background-size: 33% 13px;
        }
    }
    .dots::after {
        background-color: $primary;
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: 32px;
        width: 1px;
        @include bp($md) {
            right: 6%;
            bottom: initial;
            left: 6%;
            width: 88%;
            height: 1px;
        }
    }
}
