.text-content-feature {
  .f-display-3,
  .f-display-3 * {
    line-height: (40/34);
    letter-spacing: 0.015em;
    font-weight: 400;
    font-family: $font-family-oswald;
    @include font-size(34);
    text-transform: uppercase;
    color: $primary;
    b, strong {
      color: $secondary-1-1;
    }
  }
  >p {
    max-width: 528px;
  }
}

.flexi-bonus-table-block {
  h3.f-display-3.mb-9 {
    margin-bottom: 1rem !important;
  }
}

.flexi-bonus-table-block__feature-col {
  position: relative;
  padding-left: 2rem;
  &::before {
    content: " ";
    display: block;
    position: absolute;
    height: 100%;
    width: 1px;
    background: $primary;
    left: 0;
  }
}
