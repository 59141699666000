/*------------------------------------*\
  # BOX
\*------------------------------------*/

.box-1 {
	padding: var(--spacer-5, 20px);

	@include bp($tablet-p) {
		padding: var(--spacer-7);
	}

	@include bp($desktop) {
		padding: var(--spacer-11);
	}

}

.box-2 {
	padding: var(--spacer-6, 20px);

	@include bp($tablet-p) {
		padding: var(--spacer-9);
	}

	@include bp($desktop) {
		padding: var(--spacer-12);
	}

}