$product-card-transition: 0.25s ease-out;

.flexi-product-card-deck {
  --card-title-height: 57px;
  @include bp($md) {
    --card-title-height: 52px;
  }
  @include bp($xl) {
    --card-title-height: 57px;
  }
  .card {
    overflow: hidden;
    position: relative;
    border: 0;
    padding: 0 0 var(--card-title-height, 57px) 0;
    &.clicked {
      .card-body {
        transform: translate3d(0, 0, 0);
        &::after {
          background-color: $white;
        }
      }
      .card-title {
        color: $primary-2-3;
        transform: translate3d(0, 23px, 0);
      }
      .card-content {
        transform: translate3d(0, 23px, 0);
      }
    }
    cursor: default;
  }
  .card-img-top {
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: center center;
  }
  .card-body {
    position: absolute;
    background-color: $primary;
    height: 100%;
    transition: transform $product-card-transition;
    will-change: transform;
    transform: #{""+translate3d(0, calc(100% - ( var(--card-title-height) + 1px)), 0)};
    padding: 0;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: -14px;
      left: calc(50% - 14px);
      background-color: $primary;
      will-change: background-color;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      z-index: 2;
      overflow: visible;
      transition: background-color $product-card-transition;
    }
  }
  .card-content {
    padding: 12px 28px 22px;
    color: #F5E8D4;
    transform: translate3d(0, 0, 0);
    will-change: transform;
    transition: transform $product-card-transition;
    @include font-size(16);
    @include bp($md) {
      @include font-size(12);
    }
    @include bp($xl) {
      @include font-size(16);
    }
  }
  .card-title {
    font-family: $font-family-inter;
    padding: 15px 28px;
    @include font-size(16);
    @include bp($md) {
      @include font-size(12);
    }
    @include bp($xl) {
      @include font-size(16);
    }
    line-height: (29/16);
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1em;
    color: $white;
    margin: 0;
    transform: translate3d(0, 0, 0);
    will-change: transform;
    transition: color $product-card-transition, transform $product-card-transition;
    position: relative;
    >.bi {
      position: absolute;
      top: 16px;
      right: 9px;
    }
  }
  .card-link {
    font-family: $font-family-inter;
    text-transform: uppercase;
    @include font-size(12);
    @include bp($xl) {
      @include font-size(13);
    }
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.1em;
    color: $white;
    margin: 0;
  }
}
