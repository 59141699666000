.form-block__form {
  @include font-size(16);
  p,
  div {
    @include font-size(16);
  }
  h4,
  .f-display-4 {
    color: $warning;
    text-transform: uppercase;
    font-family: $font-family-inter;
    letter-spacing: 0.1em;
    line-height: (22/16);
    font-weight: 700;
    font-size: 16px !important;
    margin-top: 4.375rem;
    &:first-of-type {
      margin-top: 0;
    }
  }
}

.flexi-form-block {
  .form-control,
  .form-select {
    border-color: $primary;
		font-family: $font-family-inter;
		line-height: 1.847;
		letter-spacing: 0.0125em;
		font-weight: 400;
		color: $primary;
    &::placeholder {
      color: #121212;
    }
  }
  .gfield.gfield--type-submit {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    max-width: calc(100% - 304px);
    min-width: 116px;
    margin-bottom: 2rem;
    >button {
      border: 1px solid $primary;
    }
  }
  .f-display-4:has(a) {
    margin-top: 1rem;
    font-family: $font-family-oswald;
    font-weight: 400;
    line-height: (25/19);
    color: $primary;
    letter-spacing: 0.0125em;
    >a {
      @include font-size(19);
    }
  }
}
