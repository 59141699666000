$plyr-color: #B9BEC4 !default;
$plyr-bg-color: #FFFFFF !default;

.plyr {
  --plyr-color-main: #B9BEC4;
  --plyr-control-spacing: 8px;
  --plyr-control-icon-size: 12px;
  @include bp($lg) {
    --plyr-control-spacing: 12px;
    --plyr-control-icon-size: 18px;
  }
  width: 100%;
  &:hover {
    .plyr__controls {
      transform: translate3d(0, 0, 0);
    }
  }
  .plyr__controls {
    transform: translate3d(0, 100%, 0);
  }
}

.plyr__poster {
  object-fit: cover !important;
  background-size: cover;
}

// .plyr--full-ui input[type=range] {
//   color: $plyr-color;
// }

// .plyr__control--overlaid {
//   background: rgba($plyr-color, .8);
// }

// .plyr--video .plyr__control.plyr__tab-focus,
// .plyr--video .plyr__control:hover,
// .plyr--video .plyr__control[aria-expanded=true] {
//   background: $plyr-color;
// }

// .plyr__control.plyr__tab-focus {
//   box-shadow: 0 0 0 5px rgba($plyr-color, .5);
// }

// .plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
//   background: $plyr-color;
// }

#main {
  .plyr,
  .plyr * {
    // color: $plyr-bg-color;
    color: #fff;
  }
}
