.flexi-multi-detail-image-block {
    .row:last-child {
        padding-bottom: 62px !important;
        @include bp($md) {
            padding-bottom: 160px !important;
        }
    }
    .rtl {
        .image {
            @include bp($xs $md) {
                margin-bottom: 62px;
            }
            @include bp($xl) {
                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .content {
            @include bp($xl) {
                padding: 48px 0;
            }
        }
    }
    .ltr {
        .image {
            order: 2;
            @include bp($xl) {
                padding-left: 8.333%;
                img {
                    width: 100%;
                }
            }
        }
        .content {
            order: 1;
            @include bp($xs $md) {
                margin-bottom: 52px;
            }
        }
    }
}
