.flexi-news-list-block {
 
  .row > *{
    margin-top: 54px;
  }
  
  .card-body {
    padding: 18px 20px 18px 20px;
    min-height:176px;
    display: flex;
    flex-direction: column;
  }

  .card-footer {
    background-image: url("../assets/img/deco-03.svg");
    background-repeat: repeat repeat;
    background-position: top left;
    background-size: 350px 60px;

    .btn{
      i.bi-arrow-bar-down{
        &::before{
          content:"";
          background: url("../assets/img/icons/download-icon.svg");
          width:18px;
          height:22px;
          background-size: 100%;
        }
      }

      i.bi-arrow-right-short{
        &::before{
          content:"";
          background: url("../assets/img/icons/arrow-top-right.svg");
          width:18px;
          height:18px;
          background-size: 100%;
          top:-4px;
          position: relative;
          left:4px;
        }
      }
    }
  }

  .card-title {
    font-family: $font-family-roboto-slab;
    line-height: 1.3;
    letter-spacing: 0.0125em;
    font-weight: 400;
    color: $primary; 
    margin-top: auto;
  }

  .news-category{
    @include font-size(12);
    text-transform: uppercase;
    color: var(--color-green);
    font-weight: 700;
  }
  
  .news-block{
    .card-image{
      overflow: hidden;
      aspect-ratio: 350/200;
  
      img {
        -webkit-transition: 0.4s ease-in-out all;
        transition: 0.4s ease-in-out all;
      }
    }

    &:hover{
      .card-image{
        img {
          -webkit-transform: scale(1.05);
          transform: scale(1.05);
        }
      }
    }
  }
}
