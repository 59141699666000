.flexi-yet-another-one-off-section {
  .dots {
    > div {
      position: relative;

      &::before {
        content: " ";
        position: absolute;
        width: 7px;
        height: 7px;
        background: $primary;
        left: 0;
        top: calc(50% - 3px);
        border-radius: 50%;
      }
    }

    &::after {
      background-color: $primary;
      position: absolute;
      content: " ";
      top: 0;
      bottom: 0;
      left: 3px;
      width: 1px;
    }
  }
}

.container-xs-sm-0 {
  @include bp($xs $sm) {
    max-width: calc(100% - 64px);
  }
}

.flexi-yet-another-one-off-section__lower {
  background-image: url("../assets/img/lines-background-primary-3-1.svg");
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 1920px 24px;
  @include bp(0 $lg) {
    > .container {
      max-width: 100% !important;
    }
  }
}

.flexi-yet-another-one-off-section__image-col {
  img {
    aspect-ratio: 16 / 9;
    @include bp($sm) {
      aspect-ratio: 3 / 4;
    }
    object-fit: cover;
  }
}

