.flexi-news-external-article-block{
  .breadcrumb{
    margin: 0;
    li{
      list-style: none;
      color: $primary;

      &::before{
        content:""
      }

      a{
        text-decoration: underline;
        text-decoration-color: currentColor;
      }
    }
  }

  .breadcrumb-main{
    margin-bottom: 46px;

    @include bp($md) {
      margin-bottom: 64px;
    }
  }
}

.external-article-block {
  display: flex;
  flex-direction: column;

  @include bp($lg) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .article-title{
    max-width:100%;
    width:100%;

    @include bp($lg) {
      max-width:676px;
    }
  }

  .article-btn{
    padding-left: 0px;
    padding-top:40px;

    @include bp($md) {
      padding-left: 0px;
      padding-top:40px;
    }

    @include bp($lg) {
      padding-left: 40px;
      padding-top:0px;
    }

    .c-button-1{
      @include font-size(16);
      font-weight:700;
      border-width:1px;
      border-color: var(--color-green);

      &:hover{
        color: #fff;
        background-color: var(--color-green);

        .icon path{
          stroke: #FFFFFF
        }
      }
      
      .icon path{
        fill: transparent
      }

      .icon{
        width:14px;
        height:14px;
        margin-inline-end: 0em;
      }
    }
  }

  .article-title{
    h2{
      @include font-size(21);
      line-height: (25/21);
      font-family: $font-family-roboto-slab;

      @include bp($md) {
        @include font-size(24);
        line-height: (26/24);
      }
      @include bp($lg) {
        @include font-size(42);
        line-height: (50/42);
      }
    }
  }
}
