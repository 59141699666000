/*------------------------------------*\
	# BUTTONS
\*------------------------------------*/

/* = FORM BUTTONS
----------------------------------------------- */

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	// background: color__(primary);
	// border: none;
	cursor: pointer; /* Improves usability and consistency of cursor style between image-type 'input' and others */
	-webkit-appearance: button; /* Corrects inability to style clickable 'input' types in iOS */
}

// button:hover,
// input[type="button"]:hover,
// input[type="reset"]:hover,
// input[type="submit"]:hover {

// }

// button:focus,
// input[type="button"]:focus,
// input[type="reset"]:focus,
// input[type="submit"]:focus,
// button:active,
// input[type="button"]:active,
// input[type="reset"]:active,
// input[type="submit"]:active {

// }

/* = BUTTON RESET
----------------------------------------------- */

.button-reset,
.button-reset-child > button {
	background: none;
	border: none;
	padding: 0;
	text-align: left;
}

/* = GENERAL BUTTONS
----------------------------------------------- */

[class*="c-button-"] {
	display: inline-flex;
	align-items: center;
	justify-content: space-around;
	border: 2px solid currentColor;
	padding: 0.75em 1.375em; // Adjusted to match design

	@include font-size(14);
	font-family: $font__sans-serif;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.12em;
	text-decoration: none;
	text-align: center;

	transition: 0.2s ease-in-out all;

	cursor: pointer;

	@include bp($desktop) {
		@include font-size(16);
	}

	.site-content & {
		&::after {
			display: none; // Reset for common links
		}
	}
}

[class*="c-button-"] {
	&:hover {
		color: color__(white);
		background-color: color__(primary);
		border-color: color__(primary);
	}
}

[class*="c-button-"] {
	&:focus,
	&:active,
	&:focus {
		color: color__(white);
		background-color: color__(secondary);
		border-color: color__(secondary);
	}
}

.c-button--mirror {
	direction: rtl;
}

/* = BUTTON with ICON
----------------------------------------------- */

.c-button__text + .c-button__icon {
	margin-inline-start: 1em;
}

[class*="c-button-"] .c-button__icon {
	margin-inline-end: -0.5em; // Nicer button padding
}

/* = BUTTON 1
----------------------------------------------- */

.c-button-1 {
	color: color__(primary);
	background-color: transparent;
	border-color: currentColor;
}

/* = BUTTON 2
----------------------------------------------- */

.c-button-2 {
	color: color__(primary);
	background-color: transparent;
	border-color: currentColor;
	border-width: 1px;
	letter-spacing: 0.1em;
	@include font-size(14);
	// background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 10 10' width='10' height='10'%3E%3Cpath opacity='0' fill='%23A9CFD8' d='M1.56 5.01c0 .86-.7 1.56-1.56 1.56V3.44c.87 0 1.56.7 1.56 1.57zM10 3.44v3.13a1.56 1.56 0 1 1 0-3.13zM6.57.16a1.57 1.57 0 0 1-3.13 0L3.45 0h3.11l.01.16zm0 9.68a2 2 0 0 1 0 .16H3.44v-.16a1.56 1.56 0 1 1 3.12 0z'/%3E%3C/svg%3E");
	transition: all 0.15s $transition-main;
	&:hover,
	&:active,
	&:focus {
		color: color__(primary);
		background-color: #DCECEF;
		border-color: currentColor;
		// background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 10 10' width='10' height='10'%3E%3Cpath opacity='0.625' fill='%23A9CFD8' d='M1.56 5.01c0 .86-.7 1.56-1.56 1.56V3.44c.87 0 1.56.7 1.56 1.57zM10 3.44v3.13a1.56 1.56 0 1 1 0-3.13zM6.57.16a1.57 1.57 0 0 1-3.13 0L3.45 0h3.11l.01.16zm0 9.68a2 2 0 0 1 0 .16H3.44v-.16a1.56 1.56 0 1 1 3.12 0z'/%3E%3C/svg%3E");

	}
	.icon {
		width: 11px;
		height: 11px;
	}
	.c-button__icon {
		margin-inline-start: 0.5em;
	}
}

.text-white {
	.c-button-2 {
		border-color: rgba(#fff, 0.75);
		&:hover,
		&:active,
		&:focus {
			border-color: #fff;
			// background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 10 10' width='10' height='10'%3E%3Cpath opacity='0.25' fill='%23FFFFFF' d='M1.56 5.01c0 .86-.7 1.56-1.56 1.56V3.44c.87 0 1.56.7 1.56 1.57zM10 3.44v3.13a1.56 1.56 0 1 1 0-3.13zM6.57.16a1.57 1.57 0 0 1-3.13 0L3.45 0h3.11l.01.16zm0 9.68a2 2 0 0 1 0 .16H3.44v-.16a1.56 1.56 0 1 1 3.12 0z'/%3E%3C/svg%3E");
      background-color: #DCECEF;
		}
	}
}

/* = BUTTON - SMALL
----------------------------------------------- */

.c-button--sm {
	@include font-size(12);
}

/* = BUTTON LINK
----------------------------------------------- */

.c-link-1 {
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	text-transform: uppercase;
	color: currentColor;
	text-decoration: none;
	border: none;

	@include font-size(14);
	font-family: $font__sans-serif;
	font-weight: 700;
	letter-spacing: 0.12em;

	@include bp($tablet) {
		@include font-size(16);
	}

	.c-button__text {
		display: block;
		border-bottom: 2px solid currentColor;
		transition: 0.2s ease-in-out all;
	}

	.c-button__icon {
		max-height: 0.8em;
	}

	.c-icon-external-link {
		position: relative;
		top: -0.15em;
	}

	&:hover,
	&:focus,
	&:active {
		color: color__(primary);

		.c-button__text {
			border-color: currentColor;
		}
	}
}

[class*="c-link-"] .c-button__text + .c-button__icon {
	margin-left: 0.5em;
}
.site-content {
	.btn {
		letter-spacing: 0.1em;
		font-family: $font-family-inter !important;
		font-weight: 500;
		line-height: 1.2;
		padding-block: 12px;
		padding-inline: 17px;
		display: inline-flex;
		&[class*="-outline-"] {
			border-width: 1px;
		}
		> i.bi::before {
			transition: transform 0.15s $transition-main;
			margin-left: 11px;
			margin-right: 3px;
			transform: translate3d(0, 0, 0);
		}
		&:hover,
		&:active,
		&:focus {
			a {
				text-decoration: none;
			}
			> i.bi::before {
				transform: translate3d(3px, 0, 0);
			}
		}
		&.btn-link {
			position: relative;
			&::before {
				content: " ";
				display: block;
				position: absolute;
				width: 0;
				bottom: -2px;
				overflow: visible;
				left: 0;
				height: 1px;
				background: $primary;
				transition: width 0.15s $transition-main;
			}
			&:hover,
			&:active,
			&:focus,
			&.active {
				&::before {
					width: 100%;
				}
			}
			&:hover,
			&:active,
			&:focus {
				background-color: initial;
				a {
					// color: #fff;
					text-decoration: none;
				}
			}
		}
		border-radius: 0;
		text-transform: uppercase;
		display: inline-block;
		@include font-size(14);
		@include bp($lg) {
			@include font-size(16);
		}
		&.btn-link,
		&.btn-link a,
		a {
			text-decoration: none !important;
		}
		&.btn-link-oswald {
			border: 0;
			font-family: $font-family-oswald !important;
			font-weight: 400;
			// @include font-size(20);
      font-size: clamp(1.06rem, 0.19vi + 1.02rem, 1.25rem);
			line-height: 1;
			letter-spacing: 0.0125em;
			color: #fff;
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: space-between;
			i.bi.bi-chevron-right.text-white::before {
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 13'%3E%3Cpath fill='%23fff' d='M1.45 13 0 11.56 5.1 6.5 0 1.44 1.45 0 8 6.5z'/%3E%3C/svg%3E");
			}
		}
    &.btn-select-files {
      text-transform: none;
      letter-spacing: 0.0125em;
      @include font-size(13);
      font-weight: 700;
      font-family: $font-family-inter;
      line-height: (20/13);
    }
	}
}

i.bi.bi-chevron-right {
	color: var(--bs-btn-color);
	&::before {
		content: "";
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 13'%3E%3Cpolygon points='1.45 13 0 11.56 5.1 6.5 0 1.44 1.45 0 8 6.5 1.45 13'%3E%3C/polygon%3E%3C/svg%3E");
		background-size: 8px 13px;
		width: 8px;
		height: 20px;
		background-repeat: no-repeat;
		background-position: center;
		top: 1px;
		position: relative;
		color: var(--bs-btn-color);
	}
}

a[class^=c-button] {
	&:hover,
	&:active,
	&:focus {
	  text-decoration: none;
	}
}
