.flexi-faqs-block {
  .accordion {
    --bs-accordion-bg: rgba(0,0,0,0);
    --bs-accordion-color: #fff;
    --bs-accordion-active-color: #fff;
    --bs-accordion-active-bg: rgba(0,0,0,0);
    --bs-accordion-btn-bg: rgba(0,0,0,0);
    --bs-accordion-btn-color: #{""+$primary-1-6};
    --bs-accordion-border-color: rgba(0,0,0,0);
    --bs-accordion-btn-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 18 18'%3E%3Cpath fill='none' stroke='#727687' stroke-width='2' d='M9 0v18m9-9H0'/%3E%3C/svg%3E");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 18 18'%3E%3Cpath fill='none' stroke='#727687' stroke-width='2' d='M18 9H0'/%3E%3C/svg%3E");
  }
  .accordion-header {
    border-bottom: 1px solid $neutral-1-2;
  }
  .accordion-button {
    color: $primary-1-6;
    box-shadow: initial;
  }
  .text-content,
  .text-content-sm {
    a {
      text-decoration: underline;
    }
  }
}

.flexi-faqs-block__lower {
  @include bp(0 $lg) {
    >.container {
      max-width: 100% !important;
    }
    .attachment-full-hd {
      aspect-ratio: 390 / 295;
    }
  }
  .c-button-2 {
    min-width: 200px;
  }
}
