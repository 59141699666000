/*------------------------------------*\
	# LISTS
\*------------------------------------*/

.entry-content ol {
	list-style: decimal;
	margin: 0 0 0 2.5em;
}

ul ul, ol ol, ul ol, ol ul {
	margin-top: 0;
	margin-bottom: 0;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0;
}
