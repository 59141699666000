.svg-01 {
  margin-top: 48px;
  max-width: 362px;
  display: block;
  margin-inline: auto;
  @include bp($sm) {
    margin-top: -54px;
    max-width: 508px;
    margin-right: initial;
  }
  @include bp($lg) {
    margin-top: -207px;
  }
  &svg {
    display: block;
    width: 100%;
  }
}
